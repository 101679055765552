import { UpperCasePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import meta from '@core/localization/de/meta';
import { HasMetadata } from '@core/seo/site-meta.types';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';

export interface PageSection {
    title?: string;
    /**
     * Rich text content
     */
    content?: string;
    children?: PageSection[];
}

@Component({
    selector: 'fainin-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, UpperCasePipe, NgFor, NgIf],
})
export class PrivacyComponent implements HasMetadata {
    pageMetadata = of({
        lang: 'de-DE',
        title: meta.privacy.title,
        description: meta.privacy.description,
    });

    sections: PageSection[] = [
        {
            children: [
                {
                    title: 'Zweck und Ziel der Datenschutzerklärung',
                    content: `<p>Der Schutz Ihrer personenbezogenen Daten ist uns, der fainin GmbH (nachfolgend „fainin“ oder „wir“), ein wichtiges Anliegen. Diese Datenschutzerklärung informiert Sie darüber, welche personenbezogenen Daten wir im Rahmen der Nutzung unserer Plattform erheben, wie wir diese verarbeiten und welche Rechte Ihnen zustehen.</p>`,
                },
                {
                    title: 'Relevanz der Datenschutzrichtlinien im Zusammenhang mit unseren Diensten',
                    content: `<p>Die Nutzung unserer Plattform erfordert das Vertrauen unserer Nutzerinnen und Nutzer in die Sicherheit ihrer Daten. Durch transparente Informationen zur Datenverarbeitung möchten wir dieses Vertrauen stärken und sicherstellen, dass Ihre Daten gemäß der Datenschutz-Grundverordnung (DSGVO) und anderer geltender Datenschutzgesetze verarbeitet werden.</p>`,
                },
            ],
        },
        {
            title: 'Verantwortliche und Kontakt',
            children: [
                {
                    title: 'Verantwortliche Stelle:',
                    content: `<p>Die fainin GmbH (nachfolgend „fainin“ oder „wir“) ist im Sinne der Datenschutz-Grundverordnung (DSGVO) verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten.</p>
<p>Unsere Kontaktdaten lauten:<br>
fainin GmbH<br>
Mühlenkamp 4, 22303 Hamburg Deutschland<br>
E-Mail: contact@fainin.com</p>
<p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt gemäß Artikel 4 Nr. 7 DSGVO. Als verantwortliche Stelle tragen wir die Verantwortung dafür, dass Ihre Daten in Übereinstimmung mit den geltenden Datenschutzgesetzen verarbeitet werden.</p>`,
                },
                {
                    title: 'Ansprechpartner für Datenschutz:',
                    content: `<p>Wir nehmen den Schutz Ihrer Daten sehr ernst. Bei Fragen oder Anliegen zum Datenschutz wenden Sie sich bitte direkt an uns über die oben genannten Kontaktdaten. Wir haben keinen gesonderten Datenschutzbeauftragten, da dies für unser Unternehmen gemäß Artikel 37 DSGVO nicht verpflichtend ist.</p>`,
                },
            ],
        },
        {
            title: 'Zwecke und Rechtsgrundlagen der Datenverarbeitung',
            content: `<p>Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den gesetzlichen Vorgaben, insbesondere der Datenschutz-Grundverordnung (DSGVO). Nachfolgend erläutern wir die Zwecke der Datenverarbeitung und die entsprechenden Rechtsgrundlagen. </p>`,
            children: [
                {
                    title: 'Erfüllung vertraglicher und vorvertraglicher Pflichten (Art. 6 Abs. 1 lit. b DSGVO)',
                    content: `
<p>Die Verarbeitung personenbezogener Daten ist notwendig, um unsere Plattform bereitzustellen und die vertraglichen Verpflichtungen Ihnen gegenüber zu erfüllen. Dazu zählen:</p>
<ul>
    <li><strong>Bereitstellung der Plattform:</strong> Verarbeitung von Registrierungsdaten (z. B. Name, E-Mail-Adresse, Passwort) zur Authentifizierung und Verwaltung Ihres Nutzerkontos.</li>
    <li><strong>Abwicklung von Transaktionen:</strong> Verarbeitung von Zahlungsdaten über sichere Dienste wie PayPal und Stripe zur Durchführung von Zahlungen und Rückerstattungen.</li>
    <li><strong>Kommunikation:</strong> Nutzung Ihrer Kontaktdaten zur Bereitstellung von Informationen über Änderungen, Bestätigungen und Support-Anfragen.</li>
</ul>
<p>Diese Datenverarbeitung ist unerlässlich, um die vereinbarten Leistungen zu erbringen und den Betrieb der Plattform zu ermöglichen.</p>
`,
                },
                {
                    title: 'Wahrung berechtigter Interessen (Art. 6 Abs. 1 lit. f DSGVO)',
                    content: `
<p>Die Verarbeitung bestimmter Daten erfolgt zur Wahrung unserer berechtigten Interessen, insbesondere zur Optimierung, Sicherheit und Weiterentwicklung der Plattform. Dazu gehören:</p>
<ul>
    <li><strong>Plattformoptimierung:</strong> Analyse von Nutzungsdaten mit Tools wie Google Analytics und Hotjar zur Verbesserung der Nutzererfahrung.</li>
    <li><strong>Sicherheitsmaßnahmen:</strong> Schutz vor Missbrauch und Betrug, etwa durch Identitätsprüfungen mit Schufa oder SumSub.</li>
</ul>
<p>Diese Maßnahmen helfen uns, unsere Plattform weiterzuentwickeln und sicherer zu gestalten.</p>
                    `,
                },
                {
                    title: 'Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)',
                    content: `
<p>In bestimmten Fällen verarbeiten wir Ihre personenbezogenen Daten auf Grundlage Ihrer ausdrücklichen Einwilligung. Dazu zählen:</p>
<ul>
    <li><strong>Tracking und Analyse:</strong> Nutzung von Tools wie Facebook Pixel oder TikTok Pixel zur Optimierung von Werbemaßnahmen und Analyse des Nutzerverhaltens.</li>
    <li><strong>Versand von Newslettern:</strong> Verwendung Ihrer E-Mail-Adresse oder Telefonnummer zur Übermittlung von Informationen und Angeboten, sofern Sie zugestimmt haben.</li>
</ul>
<p>Ihre Einwilligung kann jederzeit widerrufen werden. Der Widerruf gilt jedoch nur für zukünftige Verarbeitungen und hat keine Auswirkungen auf die bisherige Verarbeitung.</p>`,
                },
                {
                    title: 'Erfüllung rechtlicher Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO)',
                    content: `
<p>Wir verarbeiten Ihre Daten auch, um gesetzliche Vorgaben einzuhalten, insbesondere:</p>
<ul>
    <li><strong>Steuerrecht und Buchhaltung:</strong> Speicherung von Rechnungen, Transaktionen und Zahlungsbelegen gemäß den gesetzlichen Aufbewahrungspflichten.</li>
    <li><strong>Behördliche Anfragen:</strong> Weitergabe von personenbezogenen Daten an Behörden, soweit dies gesetzlich vorgeschrieben ist.</li>
</ul>
<p>Diese Datenverarbeitung erfolgt ausschließlich zur Erfüllung rechtlicher Anforderungen.</p>`,
                },
                {
                    title: 'Plattformbezogene Datenverarbeitungen',
                    content: `
<ol>
    <li>
        <strong>Erstellung und Verwaltung von Nutzerkonten:</strong>
        <ul>
            <li><strong>Daten:</strong> Unter anderem Name, E-Mail-Adresse, Telefonnummer, Passwort.</li>
            <li><strong>Zweck:</strong> Sicherstellung einer personalisierten und sicheren Kontoverwaltung.</li>
        </ul>
    </li>
    <li>
        <strong>Nutzungsdaten:</strong>
        <ul>
            <li><strong>Daten:</strong> Interaktionen, hochgeladene Inhalte, Bewertungen.</li>
            <li><strong>Zweck:</strong> Verbesserung der Plattform und Einhaltung von Richtlinien.</li>
        </ul>
    </li>
    <li>
        <strong>Zahlungsabwicklung:</strong>
        <ul>
            <li><strong>Daten:</strong> Transaktionsdaten, verschlüsselte Zahlungsinformationen.</li>
            <li><strong>Zweck:</strong> Sicherstellung einer zuverlässigen und sicheren Zahlungsabwicklung.</li>
        </ul>
    </li>
    <li>
        <strong>Kommunikation:</strong>
        <ul>
            <li><strong>Daten:</strong> Kontaktdaten, Gesprächsprotokolle.</li>
            <li><strong>Zweck:</strong> Bearbeitung von Anfragen und Bereitstellung von Support.</li>
        </ul>
    </li>
    <li>
        <strong>Marketing und Analyse:</strong>
        <ul>
            <li><strong>Dienste:</strong> Google Analytics, Microsoft Clarity, Facebook Pixel.</li>
            <li><strong>Daten:</strong> Browser- und Gerätedaten, Nutzungsstatistiken.</li>
            <li><strong>Zweck:</strong> Durchführung gezielter Werbemaßnahmen und Optimierung der Plattform.</li>
        </ul>
    </li>
    <li>
        <strong>Hosting und Infrastruktur:</strong>
        <ul>
            <li><strong>Dienste:</strong> Microsoft Azure, AWS S3.</li>
            <li><strong>Zweck:</strong> Sicherstellung einer stabilen und sicheren Bereitstellung der Plattforminhalte.</li>
        </ul>
    </li>
</ol>`,
                },
            ],
        },
        {
            title: 'Welche Daten verarbeiten wir beim Besuch unserer Webseite?',
            content: `<p>Wir erfassen verschiedene Daten, die entweder technisch notwendig sind, um Ihnen Inhalte bereitzustellen, oder die zur Analyse und Optimierung unserer Dienste verwendet werden.</p>`,
            children: [
                {
                    title: 'Automatisch erfasste Daten',
                    content: `<p>Folgende Informationen werden automatisch durch unsere Server oder durch eingesetzte Technologien erfasst:</p>
<ul>
    <li><strong>IP-Adresse:</strong> Zur Identifikation des Endgeräts und zur Absicherung gegen unberechtigte Zugriffe.</li>
    <li><strong>Browsertyp und -version:</strong> Zur Optimierung der Anzeige unserer Webseite.</li>
    <li><strong>Betriebssystem:</strong> Zur Sicherstellung der Kompatibilität mit Ihrem Endgerät.</li>
    <li><strong>Datum und Uhrzeit des Zugriffs:</strong> Für Fehleranalyse und Sicherstellung des Betriebs.</li>
    <li><strong>Referrer-URL:</strong> Um zu erkennen, wie Sie auf unsere Webseite gelangt sind.</li>
</ul>
<p><strong>Rechtsgrundlage:</strong> Die Verarbeitung dieser Daten erfolgt auf Grundlage unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO, da sie notwendig ist, um die Webseite bereitzustellen und abzusichern.</p>`,
                },
                {
                    title: 'Verwendung von Cookies und Tracking-Technologien',
                    content: `
<p>Wir verwenden Cookies und ähnliche Technologien, um unsere Webseite nutzerfreundlicher zu gestalten und unsere Dienste zu verbessern:</p>
<ol>
    <li>
        <strong>Notwendige Cookies:</strong>
        <ul>
            <li><strong>Zweck:</strong> Gewährleistung grundlegender Funktionen wie Logins und Sicherheit.</li>
            <li><strong>Rechtsgrundlage:</strong> Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung).</li>
        </ul>
    </li>
    <li>
        <strong>Analyse- und Tracking-Cookies:</strong>
        <ul>
            <li><strong>Zweck:</strong> Sammlung von Nutzungsstatistiken und Optimierung von Werbung.</li>
            <li><strong>Genutzte Tools:</strong>
                <ul>
                    <li>Google Analytics, Hotjar, Microsoft Clarity (Analyse der Nutzererfahrung).</li>
                    <li>Facebook Pixel, TikTok Pixel (Zielgruppenoptimierung für Werbung).</li>
                </ul>
            </li>
            <li><strong>Rechtsgrundlage:</strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung).</li>
        </ul>
    </li>
    <li>
        <strong>Funktionale Cookies:</strong>
        <ul>
            <li><strong>Zweck:</strong> Speicherung von Nutzereinstellungen und Personalisierung der Plattform.</li>
            <li><strong>Rechtsgrundlage:</strong> Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse).</li>
        </ul>
    </li>
</ol>
<p>Verwaltung von Cookies: Ihre Einwilligung und Präferenzen können Sie jederzeit über unser Cookie-Banner anpassen oder durch Ihre Browser-Einstellungen ändern.</p>`,
                },
                {
                    title: 'Eingesetzte Dienste von Drittanbietern',
                    content: `<p>Um unsere Webseite zu verbessern, verwenden wir Dienste von Drittanbietern:</p>
<ul>
    <li><strong>Hubspot, TalkJS:</strong> Für die Kommunikation mit und zwischen unseren Nutzern.</li>
    <li><strong>Schufa, SumSub:</strong> Zur Identitätsverifizierung und Betrugserkennung.</li>
    <li><strong>Microsoft Azure, Amazon S3:</strong> Für Hosting und sichere Speicherung der Daten.</li>
</ul>
<p><strong>Rechtsgrundlage:</strong> Abhängig vom Dienst erfolgt die Verarbeitung auf Grundlage von Art. 6 Abs. 1 lit. b, lit. c oder lit. a DSGVO.</p>`,
                },
            ],
        },
        {
            title: 'Wer ist Empfänger meiner Daten?',
            content: `<p>Ihre personenbezogenen Daten werden nur an Dritte weitergegeben, wenn dies gesetzlich erlaubt ist, zur Erfüllung unserer vertraglichen Verpflichtungen erforderlich ist oder Sie ausdrücklich eingewilligt haben. Nachfolgend finden Sie eine Übersicht der Empfänger und die Zwecke der Datenweitergabe:</p>`,
            children: [
                {
                    title: 'Interne Empfänger',
                    content: `<p>Innerhalb unseres Unternehmens erhalten nur die Abteilungen Zugriff auf Ihre Daten, die diese zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten benötigen:</p>
<ul>
    <li><strong>Kundensupport:</strong> Bearbeitung von Anfragen und Reklamationen.</li>
    <li><strong>IT-Abteilung:</strong> Verwaltung der Plattform und Sicherstellung der Systemstabilität.</li>
    <li><strong>Marketingabteilung:</strong> Durchführung von Werbe- und Kommunikationsmaßnahmen.</li>
</ul>`,
                },
                {
                    title: 'Externe Empfänger',
                    content: `<p>Wir arbeiten mit sorgfältig ausgewählten Dienstleistern zusammen, die in unserem Auftrag Daten verarbeiten. Dazu gehören:</p>
<ul>
    <li><strong>Hosting und Infrastruktur:</strong> Microsoft Azure und AWS zur sicheren Speicherung und Verwaltung von Plattformdaten.</li>
    <li><strong>Zahlungsabwicklung:</strong> PayPal, Stripe: Verarbeitung von Transaktions- und Zahlungsdaten zur Abwicklung von Zahlungen und Rückerstattungen.</li>
    <li><strong>Kommunikationsdienste:</strong> Hubspot und TalkJS: Versand von E-Mails, Chat Nachrichten und Benachrichtigungen.</li>
    <li><strong>Analyse- und Tracking-Tools:</strong> Google Analytics, Microsoft Clarity, Facebook Pixel und TikTok Pixel: Optimierung der Plattform und Durchführung von Marketingmaßnahmen.</li>
    <li><strong>Identitätsprüfung:</strong> Schufa und SumSub: Verifizierung der Nutzeridentität und Prävention von Betrug.</li>
</ul>
<p><strong>Rechtsgrundlage:</strong> Die Weitergabe an Auftragsverarbeiter erfolgt gemäß Art. 28 DSGVO.</p>`,
                },
                {
                    title: 'Weitergabe an Dritte',
                    content: `
                    <p>Eine Weitergabe an andere Dritte erfolgt nur in Ausnahmefällen, beispielsweise:</p>
<ul>
    <li><strong>Behörden und öffentliche Stellen:</strong> Wenn wir gesetzlich verpflichtet sind, Daten offenzulegen, z. B. im Rahmen von Strafverfolgung oder Steuerprüfungen.</li>
</ul>
<p><strong>Rechtsgrundlage:</strong> Art. 6 Abs. 1 lit. c DSGVO.</p>
`,
                },
            ],
        },
        {
            title: 'Werden meine personenbezogenen Daten außerhalb der Europäischen Union und des EWR verarbeitet?',
            content: `
            <p>Die Speicherung und Verarbeitung Ihrer personenbezogenen Daten erfolgt grundsätzlich ausschließlich in Deutschland auf Servern von Microsoft Azure und AWS.</p>
<p>In einigen Fällen können Daten in Länder außerhalb des Europäischen Wirtschaftsraums (EWR) übermittelt werden. Dies betrifft insbesondere die Nutzung von Diensten internationaler Anbieter wie Analyse- und Tracking-Tools (z. B. Google Analytics, Meta Pixel).</p>
<p>Bei solchen Übermittlungen stellen wir durch Abschluss von Standardvertragsklauseln (SCCs) mit unseren Dienstleistern ein angemessenes Datenschutzniveau sicher. Weitere Informationen zu den Schutzmaßnahmen stellen wir Ihnen gerne auf Anfrage zur Verfügung.</p>`,
        },
        {
            title: 'Inwieweit erfolgt eine automatisierte Entscheidungsfindung im Einzelfall?',
            content: `<p>Eine automatisierte Entscheidungsfindung im Sinne von Artikel 22 DSGVO findet bei uns nicht statt.</p>
<p>Im Rahmen der Bonitätsprüfung nutzen wir Bonitätsdaten, die uns von der Schufa bereitgestellt werden. Zeigt die Prüfung einen negativen Schufa-Score, veranlassen unsere Mitarbeitenden eine Stornierung. Diese Entscheidung basiert auf den Bonitätsinformationen und wird stets durch eine Person überprüft.</p>
<p><strong>Rechtsgrundlage:</strong> Die Bonitätsprüfung und die darauf basierende Entscheidung erfolgen gemäß Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung).</p>
<p>Betroffene haben das Recht, diese Entscheidung anzufechten und zusätzliche Informationen oder Beweise vorzulegen. Bitte wenden Sie sich hierzu an contact@fainin.com.</p>`,
        },
        {
            title: 'Wie lange werden meine Daten gespeichert?',
            content: `<p>Wir speichern Ihre personenbezogenen Daten in Übereinstimmung mit den gesetzlichen Vorgaben, insbesondere der Datenschutz-Grundverordnung (DSGVO). Grundsätzlich bewahren wir Ihre Daten nur so lange auf, wie dies für die Bereitstellung unserer Dienste oder zur Erfüllung rechtlicher Verpflichtungen erforderlich ist. Eine Löschung Ihrer Daten erfolgt, wenn Sie Ihr Konto löschen oder dies gesetzlich vorgeschrieben ist.</p>`,
            children: [
                {
                    title: 'Speicherung während der Nutzung',
                    content: `
<ul>
    <li><strong>Nutzerkonto:</strong> Solange Ihr Konto aktiv ist, speichern wir alle mit Ihrem Konto verbundenen Daten, um Ihnen unsere Dienste bereitzustellen.</li>
    <li><strong>Vertrags- und Transaktionsdaten:</strong> Daten im Zusammenhang mit abgeschlossenen Transaktionen bleiben ebenfalls gespeichert, solange Ihr Konto aktiv ist.</li>
</ul>
            `,
                },
                {
                    title: 'Löschung bei Kontoschließung',
                    content: `
<p>Wenn Sie Ihr Konto löschen, werden alle personenbezogenen Daten, die nicht gesetzlichen Aufbewahrungspflichten unterliegen, gelöscht.</p>
<p><strong>Daten mit gesetzlicher Aufbewahrungsfrist:</strong> Bestimmte Daten (z. B. Rechnungen oder steuerrelevante Unterlagen) müssen wir gemäß gesetzlichen Vorgaben für einen festgelegten Zeitraum aufbewahren, in der Regel 10 Jahre. Nach Ablauf dieser Frist werden auch diese Daten gelöscht.</p>
            `,
                },
                {
                    title: 'Ihre Rechte zur Datenlöschung',
                    content: `
<p>Sie haben das Recht, die Löschung Ihrer Daten zu beantragen. Dies umfasst das:</p>
<p><strong>Recht auf Löschung Ihres Kontos:</strong> Sie können jederzeit verlangen, dass wir Ihr Nutzerkonto und alle damit verbundenen personenbezogenen Daten löschen.</p>
<p><strong>So können Sie Ihr Konto löschen:</strong> Die Löschung Ihres Kontos können Sie jederzeit über die entsprechenden Einstellungen in Ihrem Nutzerprofil vornehmen oder uns über contact@fainin.com kontaktieren.</p>
            `,
                },
                {
                    title: 'Einschränkung der Verarbeitung',
                    content: `
<p>Falls eine vollständige Löschung Ihrer Daten nicht sofort möglich ist, etwa aufgrund gesetzlicher Vorgaben, wird die Verarbeitung Ihrer Daten eingeschränkt. Die Daten stehen dann nur noch für gesetzlich vorgeschriebene Zwecke zur Verfügung.</p>
<p><strong>Rechtsgrundlage:</strong> Die Speicherung Ihrer Daten erfolgt gemäß Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung) und Art. 6 Abs. 1 lit. c DSGVO (Erfüllung rechtlicher Verpflichtungen).</p>
            `,
                },
            ],
        },
        {
            title: 'Welche Datenschutzrechte habe ich?',
            content: `<p>Als betroffene Person haben Sie gemäß der Datenschutz-Grundverordnung (DSGVO) verschiedene Rechte in Bezug auf Ihre personenbezogenen Daten. Diese Rechte können Sie jederzeit geltend machen, indem Sie uns unter den nachstehenden Kontaktinformationen erreichen.</p>`,
            children: [
                {
                    title: 'Ihre Rechte im Überblick',
                    content: `
<ul>
    <li><strong>Recht auf Auskunft (Art. 15 DSGVO):</strong> Sie haben das Recht, Auskunft über die von uns verarbeiteten personenbezogenen Daten zu verlangen.</li>
    <li><strong>Recht auf Berichtigung (Art. 16 DSGVO):</strong> Sie können die Berichtigung oder Vervollständigung unrichtiger oder unvollständiger Daten verlangen.</li>
    <li><strong>Recht auf Löschung (Art. 17 DSGVO):</strong> Sie können die Löschung Ihrer personenbezogenen Daten beantragen, sofern keine rechtlichen Aufbewahrungspflichten entgegenstehen.</li>
    <li><strong>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):</strong> Sie haben das Recht, die Verarbeitung Ihrer Daten unter bestimmten Voraussetzungen einzuschränken.</li>
    <li><strong>Recht auf Datenübertragbarkeit (Art. 20 DSGVO):</strong> Sie können verlangen, dass wir Ihnen Ihre personenbezogenen Daten in einem maschinenlesbaren Format bereitstellen oder an einen anderen Verantwortlichen übertragen.</li>
    <li><strong>Widerspruchsrecht (Art. 21 DSGVO):</strong> Sie können der Verarbeitung Ihrer Daten widersprechen, insbesondere bei Direktwerbung.</li>
    <li><strong>Recht auf Widerruf der Einwilligung (Art. 7 Abs. 3 DSGVO):</strong> Sie können Ihre Einwilligung zur Verarbeitung Ihrer Daten jederzeit mit Wirkung für die Zukunft widerrufen.</li>
    <li><strong>Recht auf Beschwerde (Art. 77 DSGVO):</strong> Sie können sich bei einer Aufsichtsbehörde beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen die DSGVO verstößt.</li>
</ul>
    `,
                },
                {
                    title: 'Kontakt bei Fragen oder zur Ausübung ihrer Rechte',
                    content: `
<p>Fainin GmbH<br>
Mühlenkamp 4, 22303 Hamburg, Deutschland<br>
E-Mail: contact@fainin.com</p>`,
                },
                {
                    title: 'Aufsichtsbehörde für Datenschutz in Hamburg',
                    content: `<p>Sollten Sie mit unserer Antwort nicht zufrieden sein oder der Ansicht sein, dass die Verarbeitung Ihrer Daten gegen die DSGVO verstößt, können Sie sich an die zuständige Aufsichtsbehörde wenden:</p>
<p>Der Hamburgische Beauftragte für Datenschutz und Informationsfreiheit<br>
Ludwig-Erhard-Straße 22, 20459 Hamburg</p>`,
                },
            ],
        },
    ];
}
