import { COMMUNITY_DETAIL_FRAGMENT } from '@api/vendure/fragments.graphql';
import { gql } from 'apollo-angular';

export const GET_ACTIVE_COMMUNITIES = gql`
    query GetActiveCommunities {
        activeCommunities {
            ...CommunityDetail
        }
    }
    ${COMMUNITY_DETAIL_FRAGMENT}
`;
