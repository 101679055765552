import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Asset, Community } from '@api/generated-types';
import { FontAwesomeCoreModule } from '@core/fontAwesome-core.module';
import { NavigationService } from '@core/providers/navigation/navigation.service';
import { StateService } from '@core/providers/state/state.service';
import { TranslateModule } from '@ngx-translate/core';
import { AssetPreviewSharedModule } from '@shared/asset-preview-shared.module';
import { ButtonSharedModule } from '@shared/button-shared.module';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { canJoinCommunity } from '../../../../community/util/can-join';

export type CommunityComponentType = Pick<
    Community,
    'id' | 'slug' | 'name' | 'description' | 'totalMembers' | 'isMember' | 'visibility' | 'requirementFilters' | 'tags'
> & { featuredAsset?: Pick<Asset, 'preview'> };

type CommunityActionState = 'CAN_JOIN' | 'CAN_VIEW';

@Component({
    selector: 'fainin-community',
    templateUrl: './community.component.html',
    styleUrls: ['./community.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        AssetPreviewSharedModule,
        TranslateModule,
        RouterLink,
        ButtonSharedModule,
        SharedDirectiveModule,
        FontAwesomeCoreModule,
    ],
})
export class CommunityComponent {
    @Input() community: CommunityComponentType;
    @Input() allowActions: boolean = false;
    @Output() join: EventEmitter<CommunityComponentType> = new EventEmitter<CommunityComponentType>();

    signedIn$: Observable<boolean>;
    constructor(
        private stateService: StateService,
        private navigationService: NavigationService,
        private router: Router,
    ) {
        this.signedIn$ = this.stateService.select(state => state.signedIn);
    }

    get actionState(): Observable<CommunityActionState> {
        return this.signedIn$.pipe(
            map(signedIn => {
                if (canJoinCommunity(this.community)) {
                    return 'CAN_JOIN';
                }
                return 'CAN_VIEW';
            }),
        );
    }

    doSignIn() {
        if (this.community)
            this.navigationService.navigateBackToRoute = `/community/${this.community.slug}?action=join`;
        this.router.navigate(['auth/sign-in-landing']);
    }

    doJoinCommunity(community: CommunityComponentType) {
        this.join.emit(community);
    }
}
