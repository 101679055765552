import { Injectable } from '@angular/core';
import {
    GenerateFeedbackDescriptionInput,
    GenerateFeedbackDescriptionQuery,
    GenerateFeedbackDescriptionQueryVariables,
    GenerateProductDescriptionInput,
    GenerateProductDescriptionQuery,
    GenerateProductDescriptionQueryVariables,
} from '@api/generated-types';
import { DataService } from '@core/providers/data/data.service';
import {
    GET_OPENAI_FEEDBACK_DESCRIPTION,
    GET_OPENAI_PRODUCT_DESCRIPTION,
} from '@core/providers/data/openai/openai.graphql';
import { map, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OpenAiGeneratorService {
    constructor(private dataService: DataService) {}

    /**
     * Generate a product description using the OpenAI's implementation.
     * @param input
     */
    generateProductDescription(input: GenerateProductDescriptionInput) {
        return this.dataService
            .query<GenerateProductDescriptionQuery, GenerateProductDescriptionQueryVariables>(
                GET_OPENAI_PRODUCT_DESCRIPTION,
                {
                    input,
                },
            )
            .pipe(
                map(result => result.generateProductDescription),
                take(1),
            );
    }

    /**
     * Generate a feedback description using the OpenAI's implementation.
     * @param input
     */
    generateFeedbackDescription(input: GenerateFeedbackDescriptionInput) {
        return this.dataService
            .query<GenerateFeedbackDescriptionQuery, GenerateFeedbackDescriptionQueryVariables>(
                GET_OPENAI_FEEDBACK_DESCRIPTION,
                {
                    input,
                },
            )
            .pipe(
                map(result => result.generateFeedbackDescription),
                take(1),
            );
    }
}
