import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog } from '@core/providers/modal/modal-types';

@Component({
    selector: 'fainin-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationDialogComponent implements Dialog<void> {
    resolveWith: () => void;

    title: string;
    msg: string;
    msgParams?: { [key: string]: string };

    //button label
    confirmLabel: string = 'dialog.confirm';

    confirm() {
        this.resolveWith();
    }

    close() {
        this.resolveWith();
    }
}
