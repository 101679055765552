<div class="faq-container" *ngFor="let faq of faqList; let i = index">
    <div class="faq-question d-flex justify-content-between">
        <div [ngClass]="{ opened: faq.open }" (click)="toggleFAQ(i)">{{ faq.question }}</div>
        <ng-container *ngIf="faq.open; else closed">
            <div class="expand" (click)="toggleFAQ(i)">
                <img alt="minus" height="24" src="/assets/icon/figma/minus.svg" width="24" />
            </div>
        </ng-container>
        <ng-template #closed>
            <div class="expand" (click)="toggleFAQ(i)">
                <img alt="plus" height="24" src="/assets/icon/account-menu-mobile/plus.svg" width="24" />
            </div>
        </ng-template>
    </div>
    <div class="faq-answer" *ngIf="faq.open" [@slideDown] [innerHTML]="faq.answer"></div>
</div>
