import { Injectable } from '@angular/core';
import { ParamMap, Params } from '@angular/router';
import {
    LogSearchListViewed,
    LogSearchResultClicked,
    ProfileType,
    SearchInput,
    SearchListViewedEventInput,
    SearchResultClickedEventInput,
    SearchResultSortParameter,
    SortOrder,
} from '@api/generated-types';
import { DataService } from '@core/providers/data/data.service';
import { ResponsiveService } from '@core/providers/responsive.service';
import { SearchLocation, StateService } from '@core/providers/state/state.service';
import { decodeSortParameter, encodeSortParameter } from '@core/utils/encodeDecodeSortParams';
import { Logger } from '@core/utils/logger';
import { BehaviorSubject, lastValueFrom, Observable, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { LOG_SEARCH_LIST_VIEWED, LOG_SEARCH_RESULT_CLICKED } from './search.graphql';

/**
 * This type is used to make the UI form implementation for the sort options
 * more easy and standardized.
 */
export type SortOption = {
    [key in keyof SearchResultSortParameter]: SortOrder;
};

export const SortingOptions: { label: string; option: SortOption }[] = [
    {
        label: 'newest',
        option: { updatedAt: SortOrder.DESC },
    },
    {
        label: 'location',
        option: { location: SortOrder.ASC },
    },
    {
        label: 'priceAsc',
        option: { price: SortOrder.ASC },
    },
    {
        label: 'priceDesc',
        option: { price: SortOrder.DESC },
    },
];

/**
 * Converts sort options to a SearchResultSortParameter type.
 * The location sort parameter will get initialized with `latitude` & `longitude` being `0`.
 * @param options
 */
export function sortOptionsToParameter(options: SortOption): SearchResultSortParameter {
    if (!options.location) return { ...options } as SearchResultSortParameter;

    return {
        ...options,
        location: {
            latitude: 0,
            longitude: 0,
            sort: options.location,
        },
    };
}

/**
 * Converts SearchResultSortParameter to a SortOption type.
 * @param parameter
 */
export function sortParameterToOptions(parameter: SearchResultSortParameter): SortOption {
    return {
        ...parameter,
        location: parameter.location ? parameter.location.sort : undefined,
    };
}

export interface SearchState {
    initialTerm?: string;
    term?: string;
    collectionSlug?: string;
    collectionId?: string;
    searchLocation?: SearchLocation | null;
    radius?: number;
    sort?: SearchResultSortParameter;
    priceRange?: {
        min: number;
        max: number;
    };
    perPageMobile: number;
    perPageDesktop: number;
    channelToken?: string;
    productTemplateSlug?: string;
    ownerProfileId?: string;
    ownerType?: ProfileType;
    cityName?: string;
    shipping?: boolean;
}

const initialState: SearchState = {
    term: undefined,
    perPageDesktop: 50,
    perPageMobile: 30,
};

const logger = new Logger('SearchService');

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    private readonly stateSubject;
    protected state: SearchState;
    private localStorageKey = 'SearchState';

    private readonly mobile$: Observable<boolean>;

    constructor(
        private stateService: StateService,
        private dataService: DataService,
        private responsive: ResponsiveService,
    ) {
        const localState = this.retrieveState();
        this.state = localState ? localState : { ...initialState };

        // Load default state when search location is defined
        let searchLocation = this.state.searchLocation;
        if (searchLocation?.lng && searchLocation?.lat) {
            this.state.sort = {
                location: {
                    latitude: searchLocation.lat,
                    longitude: searchLocation.lng,
                    sort: SortOrder.ASC,
                },
            };
        }

        this.stateSubject = new BehaviorSubject<SearchState>(this.state);
        this.mobile$ = this.responsive.isMobile();
    }

    /**
     * Observable of the current search input
     */
    queryInput(): Observable<SearchInput> {
        return this.stateSubject.pipe(
            withLatestFrom(this.mobile$),
            map(([state, mobile]) => {
                const filterBy = [];
                if (state.ownerProfileId) {
                    filterBy.push(`customMapping_ownerProfileId:=${state.ownerProfileId}`);
                }
                if (state.productTemplateSlug) {
                    filterBy.push(`customMapping_templateSlug:=${state.productTemplateSlug}`);
                }
                if (state.ownerType) {
                    filterBy.push(`customMapping_ownerType:=${state.ownerType}`);
                }
                if (state.cityName) {
                    filterBy.push(`customMapping_cityName:=${state.cityName}`);
                }
                if (state.shipping) {
                    filterBy.push(`customMapping_shipping:=${state.shipping}`);
                }
                const input: SearchInput = {
                    term: state.term,
                    collectionSlug: state.collectionSlug,
                    latitude: state.searchLocation?.lat,
                    longitude: state.searchLocation?.lng,
                    sort: state.sort,
                    take: mobile ? state.perPageMobile : state.perPageDesktop,
                    filterBy,
                };
                return input;
            }),
            distinctUntilChanged(),
        );
    }

    /**
     * Sets a search state according to URL query parameters
     * @param pm URL query parameter map
     */
    initFromQueryParamMap(pm: ParamMap) {
        const sortParameterStr = pm.get('sort');
        let sortParameter = sortParameterStr
            ? decodeSortParameter(sortParameterStr)
            : {
                  updatedAt: SortOrder.DESC,
              };
        let searchLocation = this.stateService.currentState('searchLocation');
        if (pm.get('loc')) {
            const loc = pm.get('loc')?.split(',');
            if (loc?.length == 2) {
                searchLocation = {
                    lat: Number(loc[0]),
                    lng: Number(loc[1]),
                    locationString: '',
                };
            }
        }

        // Set sort by distance as default once location is defined
        if (searchLocation && !sortParameter) {
            sortParameter = { location: { latitude: 0, longitude: 0, sort: SortOrder.ASC } };
        }

        if (sortParameter?.location) {
            this.hydrateLocationSortParameter(sortParameter, searchLocation);
        }

        this.state = {
            ...initialState,
            collectionSlug: pm.get('collection') ?? undefined,
            term: pm.get('term') ?? undefined,
            initialTerm: pm.get('term') ?? undefined,
            channelToken: pm.get('channel') ?? undefined,
            sort: sortParameter,
            searchLocation,
        };

        if (pm.get('radius')) {
            this.state.radius = parseInt(pm.get('radius') ?? '10', 10);
        }
        if (pm.get('priceRange')) {
            const priceRange = pm.get('priceRange')?.split('-');
            if (priceRange?.length == 2) {
                this.state.priceRange = {
                    min: parseInt(priceRange[0], 10),
                    max: parseInt(priceRange[1], 10),
                };
            }
        }
        if (pm.get('product')) {
            this.state.productTemplateSlug = pm.get('product') ?? undefined;
        }
        if (pm.get('owner')) {
            this.state.ownerProfileId = pm.get('owner') ?? undefined;
        }
        if (pm.get('ownerType')) {
            this.state.ownerType = pm.get('ownerType') as ProfileType;
        }
        if (pm.get('city')) {
            this.state.cityName = pm.get('city') ?? undefined;
        }
        if (pm.get('shipping')) {
            this.state.shipping = pm.get('shipping')?.toLowerCase() === 'true' || pm.get('shipping') === '1';
        }
        logger.debug('initFromQueryParamMap', this.state);
        this.stateSubject.next(this.state);
    }

    /**
     * Query parameters for URL
     */
    queryParams(): Observable<Params> {
        return this.stateSubject.pipe(
            map(state => {
                let queryParams: any = {}; // init
                if (state.term) {
                    queryParams.term = state.term;
                }
                if (state.sort) {
                    queryParams.sort = encodeSortParameter(state.sort);
                }
                if (state.collectionSlug) {
                    queryParams.collection = state.collectionSlug;
                }
                if (state.channelToken) {
                    queryParams.channel = state.channelToken;
                }
                if (state.productTemplateSlug) {
                    queryParams.product = state.productTemplateSlug;
                }
                if (state.radius) {
                    queryParams.radius = state.radius;
                }
                if (state.priceRange) {
                    queryParams.priceRange = `${state.priceRange.min}-${state.priceRange.max}`;
                }
                if (state.ownerProfileId) {
                    queryParams.owner = state.ownerProfileId;
                }
                if (state.ownerType) {
                    queryParams.ownerType = state.ownerType;
                }
                if (state.cityName) {
                    queryParams.city = state.cityName;
                }
                if (state.shipping) {
                    queryParams.shipping = state.shipping ? 'true' : 'false';
                }
                return queryParams;
            }),
        );
    }

    setState<T extends keyof SearchState>(key: T, value: SearchState[T]) {
        logger.debug('SET', key, 'TO', value);
        this.state[key] = value;
        localStorage?.setItem(this.localStorageKey, JSON.stringify(this.state));
        this.stateSubject.next(this.state);
    }

    updateState(value: Partial<SearchState>) {
        logger.debug('PATCH', value);
        this.state = Object.assign(this.state, value);
        localStorage?.setItem(this.localStorageKey, JSON.stringify(this.state));
        this.stateSubject.next(this.state);
    }

    select<R>(selector: (state: SearchState) => R): Observable<R> {
        return this.stateSubject.pipe(map(selector), distinctUntilChanged());
    }

    currentState<T extends keyof SearchState>(key: T): SearchState[T] {
        return this.stateSubject.value[key];
    }

    resetState() {
        this.state = { ...initialState };
        localStorage?.removeItem(this.localStorageKey);
        this.stateSubject.next(this.state);
    }

    /**
     * The difference b/w this and the function in State Service is that the one in
     * state service is used to check validity in the app state, while this one is
     * used to check validity of the searchLocation that is selected by the user
     * in the search location filter drawer
     * @param searchLocation
     */
    selectedLocationIsValid(searchLocation: SearchLocation | null): boolean {
        if (!searchLocation) {
            return false;
        }
        return Object.keys(searchLocation).length > 0;
    }

    private addOrRemove<T>(array: Array<T>, value: T) {
        const index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    private retrieveState(): SearchState | undefined {
        const stateStr = localStorage?.getItem(this.localStorageKey) as string;
        if (stateStr) {
            return JSON.parse(stateStr);
        }
    }

    /**
     * Hydrate the longitude & latitude properties if sort by distance is enabled
     * @param sort
     * @param searchLocation
     */
    public hydrateLocationSortParameter(
        sort: Pick<SearchResultSortParameter, 'location'>,
        searchLocation?: SearchLocation | null,
    ) {
        if (!searchLocation) searchLocation = this.stateService.currentState('searchLocation');
        if (!sort.location) {
            sort.location = {
                sort: SortOrder.ASC,
                longitude: searchLocation?.lng ?? 0,
                latitude: searchLocation?.lat ?? 0,
            };
        } else {
            sort.location.longitude = searchLocation?.lng ?? 0;
            sort.location.latitude = searchLocation?.lat ?? 0;
        }
    }

    /**
     * The `logSearchListViewed` mutation allows us to register the fact that
     * the search results were viewed. This is as opposed to an auto-complete (live search)
     * scenario where we search as the customer types - in that case we wouldn't want to log every partial search term.
     */
    public async logSearchListViewed(input: SearchListViewedEventInput) {
        logger.debug('logSearchListViewed', input.queryId);
        return lastValueFrom(
            this.dataService
                .mutate<LogSearchListViewed.Mutation, LogSearchListViewed.Variables>(LOG_SEARCH_LIST_VIEWED, { input })
                .pipe(take(1)),
        );
    }

    /**
     * The `logSearchResultClicked` mutation allows us to register the fact that
     * a specific search result was clicked. This in turn allows the analytics engine
     * to calculate click rate and average click position for each search term.
     */
    public async logSearchResultClicked(input: SearchResultClickedEventInput) {
        logger.debug('logSearchResultClicked', input);
        return lastValueFrom(
            this.dataService
                .mutate<LogSearchResultClicked.Mutation, LogSearchResultClicked.Variables>(LOG_SEARCH_RESULT_CLICKED, {
                    input,
                })
                .pipe(take(1)),
        );
    }
}
