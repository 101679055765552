export default {
    browseOffers: 'Angebote durchsuchen',
    specifications: 'Spezifikationen',
    faqs: 'Häufige Fragen',
    relatedProducts: 'Ähnliche Produkte',
    nextOffer: 'Nächstes Mietangebot in deiner Nähe!',
    includingInsuranceFrom: 'Inklusive Versicherung von',
    optionalDelivery: 'Optionale Versandmöglichkeit',
    pricePrefix: 'ab',
    priceSuffix: 'pro Tag',
    offers: 'Mietangebote',
    rentProductsCloseToYou: 'Mietangebote in deiner Nähe ',
};
