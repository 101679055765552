export default {
    search: {
        title: 'No location provided',
        message: 'Please add a location to sort products by distance',
    },
    shipment: {
        title: 'Shipping not available',
        msg: 'The owner does not offer shipping for this product.',
        shippingMethod: 'Shipping Method*',
        pickup: 'Pickup',
        shipping: 'Shipping',
    },
    error: {
        title: `Error`,
    },
    warning: {
        title: `Warning`,
    },
    success: {
        title: 'Success!',
    },
    languageNotAvailable: {
        article: 'This article is not available in your language, instead the original article is displayed.',
    },
    updateCustomerPassword: {
        title: 'Success!',
        message: 'Password changed!',
    },
    updateCustomerPasswordError: {
        title: 'Password change failed!',
        message: 'Please ensure that your old password has been entered correctly.',
    },
    deleteProduct: {
        title: `Deleted`,
        message: `Product Deleted Successfully.`,
    },
    activeOrderError: {
        title: `Rental In Progress!`,
        message: `There is an ongoing rental for this item.`,
    },
    requestSent: {
        title: `Request Sent`,
        message: `Your request has been sent successfully\n\nPlease wait for the owner to reply to your request.`,
    },
    registrationSuccess: {
        title: 'Registration Completed',
        message: 'Please confirm your registration through the link in the email',
    },
    registrationError: {
        title: 'Registration Failed',
        message: 'Your account could not be created',
    },
    registrationCancel: {
        title: 'Cancel Registration',
        message: 'Would you like to cancel your registration?',
    },
    languageChange: {
        title: 'Language Changed',
        message: 'Your app language has been changed successfully!',
    },
    languageChangeError: {
        title: 'Language Not Changed',
    },
    bugReportSuccess: {
        title: 'Reported',
        message: 'You bug report has been sent successfully!',
    },
    bugReportError: {
        title: 'Report could not be sent.',
    },
    describeExperience: {
        label: 'Description (Optional)',
        title: 'How was your experience with {{recipient}}?',
        placeholder: 'Briefly describe your experience here...',
        rules: 'Description is too short',
    },
    feedback: {
        title: 'Feedback',
        placeholder: 'Leave a short description of your experience here...',
        rules: 'Description is too short',
        text: 'How was your experience?',
    },
    receiveItem: {
        title: 'Item Received',
        firstMessage:
            'Please confirm if your arrived item is not damaged, matches the listing description and is the correct item?',
        secondMessage: 'You have to take at-least two pictures of the product, for the product security reasons.',
    },
    returnItem: {
        title: 'Item Returned',
        message: 'Please tap confirm if you have received the item in the described shape.',
    },
    awaitEndRental: {
        title: 'Send Request?',
        message: 'Are you sure you want to send a request to end the rental?',
    },
    endRental: {
        title: 'Warning!',
        message: 'Would you like to end the rental?',
    },
    awaitRefund: {
        title: 'Warning!',
        message: 'Would you like to send a refund request?',
    },
    cancelRental: {
        title: 'Warning',
        message: 'Would you like to cancel the rental?',
    },
    rentalEnded: {
        title: 'Rental Ended',
        message: 'The rental of your item has successfully ended!',
    },
    refundAwaiting: {
        title: 'Request Sent',
        message: 'Your request to receive refund has been sent!',
    },
    favoriteRemoveSuccess: {
        title: 'A new favorite',
        Message: 'Product successfully removed from favorites',
        removed: 'Item removed from favorites',
    },
    favoriteRemoveError: {
        message: 'The product could not be removed from favorites',
    },
    awaitEndRentalError: {
        message: 'Request to end rental could not be sent.',
    },
    rentalEndedError: {
        message: 'The rental of your item could not be ended.',
    },
    awaitRefundError: {
        message: 'Request to get refund could not be sent.',
    },
    rentalCancelled: {
        title: 'Rental Cancelled',
        message: 'The rental of your item has been cancelled!',
    },
    rentalCancelledError: {
        message: 'The rental of your item could not be cancelled.',
    },
    orderDeliveredSuccess: {
        title: 'Success',
        message: 'Your images have been uploaded!',
    },
    orderDeliveredError: {
        title: 'Error',
        message: 'An error occurred while uploading your images.',
    },
    imageSubmitError: {
        noPictures: 'No pictures taken',
        twoPictures: 'At-least two pictures needed*',
    },
    termsAndConditionsCheck: {
        title: 'Terms & Conditions',
        message: 'Before signing up, please accept the terms and conditions.',
    },
    requestExists: {
        title: 'Request already exists',
        message: 'Your request for this item already exists for the specified dates.',
    },
    invalidDates: {
        title: 'Invalid dates picked',
        message: 'The dates specified for this request are invalid.',
    },
    itemUnavailable: {
        title: 'Item not available',
        message: 'This Item is either booked or blocked by the owner on the specified dates.',
    },
    newsletterAlreadySubscribed: {
        title: 'Information',
        message: 'You are already subscribed to our newsletter! Awesome!',
    },
    voucher: {
        message: `The coupon "{{code}}" for {{amount}}€ was applied.`,
    },
    noLocationResolved: {
        message: 'No location with coordinates could be determined for this input.',
    },
    deleteAccount: {
        request: 'Request account deletion',
        message: 'Your request to delete your account has been sent successfully.',
    },
    upgradeAccount: {
        label: 'Create Business Account',
        title: 'Upgrade your Account',
        message:
            'Would you like to upgrade your account to a business account? In the next step, you will be asked to enter your company details.',
    },
    community: {
        cantJoin: 'You cannot currently join this community.',
        missingRequirement: 'You do not have the necessary requirements to join this community.',
        noAccess: 'You do not have the necessary requirements to access this community.',
        noMember: 'You are not a member of this community',
        alreadyMember: 'You are already a member of this community',
        joined: 'You have joined the {{name}} community',
        left: 'You have left {{name}}',
        leaveFailed: 'An error occurred while leaving the community',
    },
    notSignedIn: {
        title: 'Not signed in',
        message: 'You must be signed in.',
        actionMsg: 'You must be signed in to perform this action.',
    },
};
