export default {
    homeIcon: 'Fainin Home Icon',
    searchIcon: 'Fainin Such Icon',
    lendIcon: 'Fainin Vermieter Icon',
    uploadIcon: 'Fainin Artikel Hochladen Icon',
    logoutIcon: 'Logout Icon',
    myProfileIcon: 'My Profile Icon',
    chatIcon: 'Chats Icon',
    uploadProductIcon: 'Upload Product Icon',
    rentalsIcon: 'Rentals Icon',
    favoritesIcon: 'Favorites Icon',
    discoverIcon: 'Discover Icon',
    dashboardIcon: 'Dashboard Icon',
    uploadImageAfterRental: 'Hochgeladenes Bild',
    uploadImageBeforeRentalPlaceholder: 'Platzhalter für Bild upload',
    renterThumbnail: 'Mieter Profilbild',
    fallbackRenterThumbnail: 'Standard Mieter Profilbild',
    stepPending: 'Schritt: Ausstehend',
    stepAction: 'Schritt: Aktion',
    productImageAfterRental: 'Produktbild nach Verleih',
    productImageBeforeRental: 'Produktbild vor Verleih',
    paypalIcon: 'Paypal icon',
    visaIcon: 'Visa Icon',
    masterCardIcon: 'Master card icon',
    return: 'Zurück',
    borrowWhatYouNeed: 'Leih’ Dir, was Du brauchst',
    private: 'Privat',
    businessAccount: 'Geschäftskonto',
    whatPassword: 'Wie lautet Deine Email / Passwort',
    insuranceIllustration: 'Versicherung illustration',
    faininTeam: 'fainin Team',
    faininAppMockup: 'fainin Application mockup',
    appleAppstoreIcon: 'Apple Appstore icon',
    googlePlaystoreIcon: 'Google Playstore icon',
    flyingDroneIcon: 'Fliegende Drohne icon',
    cookieSettingsButton: 'Cookie Einstellungen button',
    johann: 'fainin Johann',
    max: 'fainin Max',
    kev: 'fainin Kev',
    govtLogo: 'Deutsche Bundesstiftung Umwelt Logo',
    companyLogo: 'OVH Company Logo',
    ergoLogo: 'Ergo logo - versichern heißt verstehen',
    assetGallery: {
        productGalleryImage: 'Produkt Gallerie Bild',
        productGalleryFallbackImage: 'Produkt Gallerie Ausweichbild',
        viewAll: 'Alle anzeigen',
        faininProductLocationDot: "fainin's Produkt Standort Markierung",
    },
    accountMenu: {
        chatButtonIcon: 'Chat button icon',
        accountMenuIconSignedIn: 'Account menu icon - eingeloggt',
        accountMenuIconUnauthenticated: 'Account menu icon - nicht authentifiziert',
        burgerMenuIcon: 'Burger menu icon',
        closeDrawerIcon: 'Menu schließen icon',
        signupButton: 'Registrieren button',
        desktopLoginButton: 'Desktop login button',
    },
    cartToggle: {
        cartToggle: 'Warenkorb toggle',
    },
    collectionBreadCrumbs: {
        breadcrumb: 'Breadcrumbs',
    },
    layoutLogo: {
        faininStorefrontLogo: 'fainin Storefront Logo',
    },
    layoutSearchButton: {
        searchSheetClose: 'Suchleiste schließen button',
    },
    mobileMenuToggle: 'Mobiles menu umschalten',
    myProductCard: {
        fallbackProductPreview: 'Ausweich-Produktvorschaubild',
    },
    pageHeader: {
        back: 'Zurück',
    },
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    userComponent: {
        ownerThumbnail: 'Vermieter thumbnail',
        fallbackOwnerthumbnail: 'Ausweich Vermieter thumbnail',
    },
    dashboard: {
        downloadAndroid: 'Download für Android',
        downloadApple: 'Download für Apple',
    },
    order: {
        productPreviewImage: 'Vorschaubild vom gebuchten Produkt',
    },
    button: {
        close: 'Schließen',
        clear: 'Zurücksetzen',
    },
};
