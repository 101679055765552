export default {
    whatsPhoneNumber: 'Какой у вас номер телефона?',
    howLogin: 'Как вы хотите войти?',
    notRegisteredYet: 'Ещё не зарегистрированы?',
    appleAuthHasFailed:
        'Регистрация в системе Apple не удалась. Пожалуйста, попробуйте еще раз или свяжитесь с нашей службой поддержки',
    addMoreProducts: 'Add more products',
    gotoArticle: 'Go to Article',
    weLetYouKnow: "We'll let you know when someone sends you a rental request.",
    productUploaded: 'Your product has been uploaded.',
    congrats: 'Congratulations!',
    success: 'Успех!',
    information: 'Информация',
    email: 'Адрес электронной почты',
    password: 'Пароль',
    passwordHint: 'Длина не менее 8 символов',
    passwordHint2: 'Ваш пароль должен содержать цифры, заглавные буквы и специальный символ',
    confirmPassword: 'Подтверждение пароля',
    rememberMe: 'Оставайтесь под своим логином',
    reset: 'Сброс',
    setPassword: 'Установить пароль',
    login: 'Вход в систему',
    loginWithEmail: 'Войти в систему с помощью электронной почты',
    alreadyHaveAccount: 'Уже есть аккаунт?',
    becomeRenter: 'Станьте арендаторам',
    register: 'Регистрация',
    registerNow: 'Зарегистрируйтесь сейчас',
    mr: 'мужчина',
    mrs: 'женщина',
    notSpecified: 'Нет спецификации',
    gender: 'Пол',
    birthday: 'День рождения',
    createAccount: 'Зарегистрироваться',
    noAccount: 'Еще нет счета?',
    hasAccount: 'У вас уже есть учетная запись',
    agreedTo: 'Я принимаю',
    favoritesCannotBeSaved: 'Избранное не может быть сохранено навсегда, пока вы не войдете в систему',
    version: 'Версия',
    okay: 'Хорошо',
    filters: 'Фильтр',
    loadMore: 'Загрузить еще',
    noResults: 'Нет результатов',
    of: 'от',
    imprint: 'Оттиск',
    representedBy: 'Представлен',
    managingDirector: 'Управляющий',
    registerEntry: 'Зарегистрировать запись',
    registeredIn: 'Зарегистрирован в торговом реестре',
    registrationCourt: 'Местный суд Гамбурга',
    registrationNumber: 'Регистрационный номер',
    taxId: 'ID налога на добавленную стоимость',
    salesTaxId: 'Идентификационный номер налога с продаж согласно §27a Umsatzsteuergesetz',
    vatId: 'Идентификатор НДС',
    businessType: 'тип бизнеса',
    comingSoon: 'Скоро появится',
    type: {
        PERSONAL: 'Личный счет',
        BUSINESS: 'Бизнес-счет',
    },
    forgotPassword: {
        title: 'Забыли пароль',
        instructions: 'начала введите свою почту fainin. После этого вы получите помощь по почте',
        success:
            'Очень хорошо! Вам было отправлено электронное письмо. Пожалуйста, проверьте свой почтовый ящик, если учетная запись существует.',
    },
    resetPassword: {
        title: 'Сброс пароля',
        instructions: 'Выберите свой новый пароль fainin',
    },
    verify: {
        title: 'Добро пожаловать!',
        subTitle: 'Наконец-то вы можете начать работать и стать частью семьи fainin',
        instructions: 'Вам было отправлено электронное письмо. Теперь просто проверьте.',
        refreshToken: 'Пожалуйста, введите свой адрес электронной почты, чтобы запросить новый токен верификации',
    },
    business: {
        title: 'Компания',
        type: 'Тип компании',
        name: 'Название компании',
        registrationNumber: 'Регистрационный номер/ Символ акции',
        vat: 'Идентификатор НДС',
    },
    error: {
        googleAuthFlowRestarted: 'Sign in flow restarted by the user',
        googleFailedToIssueCred: 'Failed to issue credentials for continuing with Google, please try again.',
        googleAuthCancelledByUser: 'Sign in with Google cancelled by the user.',
        googleOneTapOptedOut:
            'Please check if you have opted out of Google one Tap Sign In. If not then please try clearing the website cache and try again.',
        googleAuthSuppressedByUser: 'Sign in with Google suppressed by the user.',
        googleAuthCustomerSupport:
            'Something went wrong for signing in with Google, if this issue persists, please contact customer support.',
        googleAuthUnsupported:
            'Your browser does not support Continuing with Google, please try using an email or in another browser.',
        initGoogleAuthError: 'Failed to initialize Google Authentication. Please refresh and try again.',
        errorOccurred: 'Произошла ошибка',
        phoneNumberError: 'Ой! Что-то не так с вашим номером телефона — вы добавили к нему код страны?',
        startBeforeEnd: 'Дата начала должна быть раньше даты окончания!',
        errorDeletingLocation: 'Ошибка при удалении местоположения',
        removeFailed: 'Удаление не удалось',
        noAddressProvided: 'Адрес улицы не указан',
        failedToFindLocation: 'Ваше текущее местоположение не удалось найти',
        failedToCreateUploadLocation: 'Место загрузки не удалось создать',
        failedToLocateAddress: 'Указанный адрес не может быть найден',
        failedToGeolocate: 'Данные геолокации не могут быть получены',
        invalidZip: 'Неверный почтовый индекс для города/штата',
        invalidCityName: 'Неверное название города',
        invalidCountryName: 'Неверное название страны',
        attention: 'Внимание!',
        cantVerifyZipTitle: 'Мы не знаем этого почтового индекса',
        cantVerifyZip: 'Вы уверены, что хотите продолжить работу с текущей записью?',
        zipCityDontMatchTitle: 'Внимание!',
        zipCityDontMatchBody: 'Почтовый индекс и город не совпадают',
        startEndDates: 'Пожалуйста, выберите дату начала и окончания (обе могут быть в один день)',
        startEndTimes: 'Пожалуйста, выберите время забора и возврата',
        invalidCredentials: 'Неверная информация. Попробовать еще раз',
        recaptcha: 'Рекаптча не прошла',
        email: 'Пожалуйста, введите вашу любимую электронную почту',
        password: 'Пожалуйста, введите правильный пароль',
        confirmPassword: 'Внимание! Два пароля разные',
        name: 'Пожалуйста, скажите нам свое имя',
        firstName: 'Пожалуйста, скажите нам свое имя',
        lastName: 'Пожалуйста, скажите нам свою фамилию',
        gender: 'Пожалуйста, выберите',
        businessName: 'Пожалуйста, введите название вашего предприятия',
        businessType: 'Пожалуйста, введите тип вашего бизнеса',
        vat: 'Пожалуйста, введите ваш идентификатор НДС',
        noToken: 'Извините, токен не найден',
        invalidToken: 'Используемый токен недействителен!',
        verificationTokenInvalid: 'Токен верификации недействителен.',
        verificationTokenExpired: 'Срок действия токена верификации истек.',
        title: 'Требуется название (не менее 3 символов)',
        image: 'Загрузить фотографию',
        category: 'Пожалуйста, добавьте категорию',
        subCategory: 'Пожалуйста, добавьте подкатегорию',
        description: "Напишите' описание (мин. 5 символов)",
        perDay: 'Минимум 1 €',
        perHour: 'Минимум 1 евро и максимум 2 десятичных знака',
        basePrice: 'Минимум 1 евро и максимум 2 десятичных знака',
        perWeek: 'Недельная цена не должна быть равна 0',
        perMonth: 'Месячная цена не должна быть равна 0',
        perWeek2: 'Недельная цена должна быть выше дневной цены',
        perMonth2: 'Месячная цена должна быть выше, чем недельная и дневная цена',
        purchasePrice: 'Минимум 1 €',
        location: 'Расположение не найдено. Пожалуйста, создайте новое место загрузки.',
        locationName: 'Пожалуйста, введите название местоположения',
        districtName: 'Пожалуйста, введите название района',
        zip: 'Требуется почтовый индекс',
        cityName: 'Пожалуйста, введите название города',
        countryName: 'Пожалуйста, введите название страны',
        agree: 'Должно быть принято!',
        duplicateEmail: 'Вы уже регистрировались с этим адресом электронной почты, попробуйте войти!',
        nativeAuthenticationMethodsNotFound:
            'Вы зарегистрированы с помощью метода социальной регистрации. Попробуйте еще раз.',
        signedUpThroughEmail: 'EСчет уже есть. Попробуйте зарегистрироваться с помощью почты',
        birthday: 'День рождения обязателен',
        validEmail: 'Пожалуйста, введите адрес электронной почты',
        checkInputs: 'Пожалуйста, проверьте введенные данные и попробуйте сохранить еще раз',
        fillAllForBank: 'Пожалуйста, заполните все необходимые поля для добавления банковского счета!',
        iban: 'IBAN не верен!',
        ibanMin: 'максимально 34 символа!',
        positionStack: 'Указанный адрес не удалось найти, проверьте его еще раз.',
        changeEmail: 'Пожалуйста, введите пароль для изменения адреса электронной почты!',
        loadingError: 'Произошла ошибка, пожалуйста, проверьте свою сеть и обновите страницу!',
        cantRemoveDefault: 'Вы не можете удалить стандартное местоположение',
    },
    migrationOptOut: {
        title: 'Отмена миграции',
        instructions:
            'Если вы начнете процедуру отказа, все ваши данные будут удалены. Конечно, вы по-прежнему можете создать новую учетную запись.',
        button: 'Opt-Out!',
        buttonLabel: 'Миграция Opt-Out Кнопка',
    },
    migrationOptOutNotification: {
        onSuccessHeading: 'Миграция отменена',
        onSuccessParagraph: 'Грустно видеть, как вы уходите! Ваши данные будут полностью удалены.',
        onError: 'Произошло что-то непредвиденное. Пожалуйста, свяжитесь с нами через contact@fainin.com!',
    },
    migrationOptIn: {
        title: 'Завершить миграцию',
        instructions: 'Добро пожаловать в семью Файниных! Теперь вы можете выбрать новый пароль.',
        button: 'Выберите пароль',
        buttonLabel: 'Миграция Opt-In Button',
    },
    migrationOptInNotification: {
        onError: 'Произошло что-то непредвиденное. Пожалуйста, свяжитесь с нами через contact@fainin.com!',
    },
    passwordResetNotification: {
        onSuccessHeading: 'Пароль сброшен.',
        onSuccessParagraph: 'Поздравляем!',
    },
    checkoutConfirmation: {
        thankYouForOrder: 'Спасибо за ваш заказ',
        orderCode: 'Номер заказа',
        placed: 'Создан',
        createAccount: 'Создать аккаунт',
        createAccountMore: 'Создайте аккаунт для ускорения платежей, отслеживания заказов и многого другого.',
        checkEmail: 'Отлично! Вы должны были получить от нас электронное письмо',
        toCompleteRegistration: 'завершить регистрацию',
        pageNotFound: 'Страница не найдена',
        payWithMethod: 'Оплатить методом',
        examplePaymentForm: 'Это пример. Пожалуйста, не используйте реальную информацию о карте',
        expiry: 'Срок годности',
        month: 'Месяц',
        year: 'Год',
        completeOrder: 'Завершенный заказ',
        change: 'Изменить',
        selectAddress: 'Выберите адрес',
        addNewAddress: 'Добавить новый адрес',
        customerDetails: 'Данные клиента',
        next: 'Далее',
        shippingAddress: 'Адрес доставки',
        sendToThisAddress: 'Доставить по этому адресу',
        shippingMethod: 'Способ доставки',
        proceedToPayment: 'Приступить к оплате',
        account: 'Счет',
        shipping: 'Доставка',
        payment: 'Оплата',
        complete: 'Полное',
        haveAccount: 'У вас уже есть аккаунт',
        or: 'или',
        continueAsGuest: 'Продолжить как гость',
    },
    google: 'Продолжить с Google',
    apple: 'Продолжить с Apple',
    continueWithMail: 'Продолжить работу с почтой',
    termsAgreement: 'спользуя fainin, вы соглашаетесь с нашими условиями использования и политикой конфиденциальности.',
    termsAgreement1: 'Используя fainin, вы соглашаетесь с нашим',
    termsAgreement2: 'Правила и условия',
    termsAgreement3: 'и',
    termsAgreement4: 'Политика конфиденциальности',
    or: 'или',
    agreeToNotify: 'Пожалуйста, примите условия обслуживания.',
    next: 'Далее',
    whatsYourName: 'Как вас зовут?',
    MandatoryField: 'Обязательное поле',
};
