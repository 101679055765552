import gql from 'graphql-tag';

export const GET_OPENAI_PRODUCT_DESCRIPTION = gql`
    query GenerateProductDescription($input: GenerateProductDescriptionInput!) {
        generateProductDescription(input: $input) {
            description
            priceRecommendation
        }
    }
`;

export const GET_OPENAI_FEEDBACK_DESCRIPTION = gql`
    query GenerateFeedbackDescription($input: GenerateFeedbackDescriptionInput!) {
        generateFeedbackDescription(input: $input) {
            description
        }
    }
`;
