import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ProductTemplateFaq, ProductTemplateFaqFragment } from '@api/generated-types';

@Component({
    selector: 'fainin-faqs-list',
    templateUrl: './faqs-list.component.html',
    styleUrls: ['./faqs-list.component.scss'],
    animations: [
        trigger('slideDown', [
            state('void', style({ height: '0', opacity: 0 })),
            state('*', style({ height: '*', opacity: 1 })),
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                animate('300ms ease-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('300ms ease-in', style({ height: '0', opacity: 0 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [CommonModule],
})
export class FaqsListComponent implements OnInit {
    @Input() faqs: ProductTemplateFaq[] = [];
    loading: boolean = true;

    faqList: (ProductTemplateFaqFragment & {
        open: boolean;
    })[];

    constructor() {}

    ngOnInit() {
        this.faqList = this.faqs.map(faq => ({
            ...faq,
            open: false,
        }));
    }

    toggleFAQ(index: number) {
        this.faqList.forEach((faq, i) => {
            faq.open = i === index ? !faq.open : false;
        });
    }
}
