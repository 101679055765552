import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from '@core/providers/google-analytics.service';
import { ScrollPositionService } from '@core/providers/scrollPosition/scroll-position-service';
import { SearchService } from '@core/providers/search/search.service';
import { StateService } from '@core/providers/state/state.service';
import { untilDestroyed } from '@core/utils/until-destroyed';
import { filter, take } from 'rxjs/operators';
import { SearchTermService } from '../../../../search/providers/search-term.service';

@Component({
    selector: 'fainin-layout-search-bar',
    templateUrl: './layout-search-bar.component.html',
    styleUrls: ['./layout-search-bar.component.scss'],
})
export class LayoutSearchBarComponent implements OnInit, OnDestroy {
    /** @deprecated */
    searchTerm = new FormControl('');

    searchTermControl = new FormControl<string | null>(null);

    @HostBinding('class.focused') private _searching: boolean;
    get searching(): boolean {
        return this._searching;
    }

    set searching(value: boolean) {
        this._searching = value;
    }

    @ViewChild('search', { static: false }) searchTermInput: ElementRef;

    constructor(
        private stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        private ga: GoogleAnalyticsService,
        private search: SearchService,
        private searchTermService: SearchTermService,
    ) {}

    ngOnInit() {
        // Get initial term from query params
        this.route.queryParams.subscribe(params => {
            if (params['term']) {
                this.searchTermControl.setValue(params['term']);
                this._searching = true;
            }
        });

        this.searchTermControl.setValue(this.searchTermService.getValue());
        this.search
            .select(state => state.initialTerm)
            .pipe(untilDestroyed(this))
            .subscribe(initialTerm => {
                // This will only happen once a term is decoded from query URL
                if (initialTerm && this.searchTerm.untouched && !this.searchTerm.value) {
                    this.searchTerm.setValue(initialTerm);
                    this.searchTermInput?.nativeElement.focus();
                    this._searching = true;
                }
            });
        this.stateService
            .select(state => state.showSubHeader)
            .pipe(
                filter(showSubHeader => !showSubHeader),
                untilDestroyed(this),
            )
            .subscribe(() => {
                // In case the subheader was closed from outside
                // check if searchbar should remain focused based on input
                this._searching = !!this.searchTerm.value;
            });

        /**
         * For updating searchTerm input when clicking the back button in the search page
         */
        this.search
            .select(state => state.term)
            .pipe(untilDestroyed(this))
            .subscribe(term => {
                this.searchTerm.setValue(term ?? '');
            });
    }

    doSearch(term: string | null) {
        this.search.setState('term', term ?? '');

        // If it's not already the search page -> navigate to it
        if (this.router.url !== '/search') {
            this.router.navigate(['/search'], { queryParams: { term }, queryParamsHandling: 'merge' });
        }

        this.searchTermInput.nativeElement.blur();
        this.searching = false;

        this.searchTermService.setTerm(term);
    }

    doClear() {
        this.searching = false;
        this.searchTermControl.reset();
        this.searchTermService.clear();
        this.router.navigate([], {
            // Remove term from query params
            queryParams: { term: undefined },
            queryParamsHandling: 'merge',
            skipLocationChange: true,
        });
        this.search.updateState({ term: undefined, initialTerm: undefined });
    }

    /* eslint-disable-next-line */
    ngOnDestroy(): void {
        // needs to be present for untilDestroyed pipe
    }
}
