import { isPlatformBrowser } from '@angular/common';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { AUTH_TOKEN_KEY } from '@core/apollo-client-provider';
import { Logger } from '@core/utils/logger';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationService } from '../notification/notification.service';

const logger = new Logger('DefaultInterceptor');

/**
 * The default interceptor examines all HTTP requests & responses and displays any error notifications.
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: any) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('X-Skip-Interceptor')) {
            const modifiedRequest = req.clone({
                headers: req.headers.delete('X-Skip-Interceptor'),
            });

            return next.handle(modifiedRequest);
        }

        return next.handle(req).pipe(
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                        this.checkForAuthToken(event);
                        this.notifyOnError(event);
                    }
                },
                error: err => {
                    console.error(err);
                    if (err instanceof HttpErrorResponse) {
                        this.notifyOnError(err);
                    } else {
                        this.displayErrorNotification(err.message);
                    }
                },
            }),
        );
    }

    private notifyOnError(response: HttpResponse<any> | HttpErrorResponse) {
        if (response instanceof HttpErrorResponse) {
            console.log('notifyOnError', response);
            if (response.status === 0) {
                this.displayErrorNotification(
                    'Es konnte keine Verbindung hergestellt werden. Überprüfe deine Verbindung oder versuche es später noch ein Mal!',
                );
            } else if (response.status === 500) {
                this.displayErrorNotification(
                    'Entschuldige, es ist ein unerwarteter Fehler aufgetreten. Versuche es später noch ein Mal oder melde dich beim Team!',
                );
            } else {
                if ('error' in response) {
                    const graqhQLErrors = response.error.errors;
                    if (graqhQLErrors && Array.isArray(graqhQLErrors)) {
                        //Don't show error on auth request
                        const firstCode: string = graqhQLErrors[0].extensions.code;
                        if (firstCode === 'FORBIDDEN') {
                            // nothing to do here
                            logger.debug('Forbidden');
                            return;
                        }
                        logger.error(
                            graqhQLErrors.map(({ message, extensions, path }) => ({
                                message,
                                extensions,
                                path,
                            })),
                        );
                        const message = graqhQLErrors.map(err => err.message || err.extensions.code).join('\n');
                        this.displayErrorNotification(message);
                    }
                }
                this.displayErrorNotification(response.message);
            }
        } else {
            // GraphQLs errors still return 200 OK responses, but have the actual error message
            // inside the body of the response.
            const graqhQLErrors = response.body.errors;
            if (graqhQLErrors && Array.isArray(graqhQLErrors)) {
                const firstCode: string = graqhQLErrors[0]?.extensions?.code;
                if (firstCode === 'FORBIDDEN') {
                    // auto logout needed?
                    logger.debug('Forbidden');
                } else if (firstCode === 'CHANNEL_NOT_FOUND') {
                    const message = graqhQLErrors.map(err => err.message).join('\n');
                    this.displayErrorNotification(message);
                } else {
                    logger.error(
                        graqhQLErrors.map(({ message, extensions, path }) => ({
                            message,
                            extensions,
                            path,
                        })),
                    );
                    const message = graqhQLErrors.map(err => err.message || err.extensions.code).join('\n');
                    this.displayErrorNotification(message);
                }
            }
        }
    }

    /**
     * We need to lazily inject the NotificationService since it depends on the I18nService which
     * eventually depends on the HttpClient (used to load messages from json files). If we were to
     * directly inject NotificationService into the constructor, we get a cyclic dependency.
     */
    private displayErrorNotification(message: string): void {
        const notificationService = this.injector.get<NotificationService>(NotificationService);
        notificationService.error(message);
    }

    /**
     * If the server is configured to use the "bearer" tokenMethod, each response should be checked
     * for the existence of an auth token.
     */
    private checkForAuthToken(response: HttpResponse<any>) {
        if (environment.tokenMethod === 'bearer' && isPlatformBrowser(this.platformId)) {
            const authToken = response.headers.get('vendure-auth-token');
            if (authToken) {
                logger.debug('Auth token received');
                localStorage.setItem(AUTH_TOKEN_KEY, authToken);
            }
        }
    }
}
