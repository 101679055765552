import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedDialogModule } from '@core/components/modal-dialog/shared-dialog.module';
import { NotificationComponent } from '@core/components/notification/notification.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CameraPermissionsDialogComponent } from '@shared/components/camera-permissions-dialog/camera-permissions-dialog.component';
import { CommunityComponent } from '@shared/components/community/card/community.component';
import { InformationDialogComponent } from '@shared/components/information-dialog/information-dialog.component';
import { RatingComponentModule } from '@shared/components/rating/rating-component.module';
import { UserSharedModule } from '@shared/components/user/user-shared.module';
import { SharedDirectiveModule } from '@shared/directive/shared-directive.module';
import FormsBarrel from '@shared/forms.barrel';
import { PageModule } from '@shared/page.module';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SwiperModule } from 'swiper/angular';
import { AssetPreviewSharedModule } from './asset-preview-shared.module';
import { ButtonSharedModule } from './button-shared.module';
import { CheckWebPSupportSharedModule } from './check-web-p-support-shared.module';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { CartContentsComponent } from './components/cart-contents/cart-contents.component';
import { CenteredCardComponent } from './components/centered-card/centered-card.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DropdownContentDirective } from './components/dropdown/dropdown-content.directive';
import { DropdownTriggerDirective } from './components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LocationPermissionModalComponent } from './components/location-permission-modal/location-permission-modal.component';
import { LocationPermissionDialogComponent } from './components/location-permission/location-permission-dialog.component';
import { SoonLabelComponent } from './components/soon-label/soon-label.component';
import { UserRatingBarComponent } from './components/user-rating-bar/user-rating-bar.component';
import { FormatPriceSharedModule } from './format-price-shared.module';
import { NewsletterSharedModule } from './newsletter-shared.module';
import { StringifyLocationSharedModule } from './stringify-location-shared.module';
import { FaqsListComponent } from './components/faqs-list/faqs-list.component';

const SHARED_DECLARATIONS = [
    UserRatingBarComponent,
    CartContentsComponent,
    AddressCardComponent,
    AddressFormComponent,
    CenteredCardComponent,
    AddressModalComponent,
    CollectionCardComponent,
    NotificationComponent,
    SoonLabelComponent,
    // Dialogs
    ConfirmationDialogComponent,
    InformationDialogComponent,
    LocationPermissionDialogComponent,
    LocationPermissionModalComponent,
    CameraPermissionsDialogComponent,
    // Directive
    DropdownTriggerDirective,
    DropdownContentDirective,
];

const IMPORTS = [
    NzStepsModule,
    FontAwesomeModule,
    CommonModule,
    OverlayModule,
    RouterModule,
    ...FormsBarrel,
    TranslateModule,
    HttpClientJsonpModule,
    NzToolTipModule,
    NzModalModule,
    LazyLoadImageModule,
    NzDropDownModule,
    AssetPreviewSharedModule,
    NewsletterSharedModule,
    NzNotificationModule,
    FormatPriceSharedModule,
    CheckWebPSupportSharedModule,
    StringifyLocationSharedModule,
    ButtonSharedModule,
    SharedDialogModule,
    SharedDirectiveModule,
    SharedPipesModule,
    PageModule,
    UserSharedModule,
    RatingComponentModule,
    DropdownComponent,
    CommunityComponent,
    FaqsListComponent,
];

@NgModule({
    declarations: [SHARED_DECLARATIONS],
    imports: [IMPORTS, SwiperModule],
    exports: [...IMPORTS, ...SHARED_DECLARATIONS],
})
export class SharedModule {}
