export default {
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    proceed: 'Fortfahren',
    acceptRequestTitle: 'Anfrage annehmen',
    acceptRequestMsg: 'Bitte bestätige die Annahme dieser Anfrage.',
    declineRequestTitle: 'Anfrage ablehnen',
    declineRequestMsg: 'Bitte bestätige die Ablehnung dieser Anfrage.',
    cancelRequestTitle: 'Anfrage stornieren',
    cancelRequestMsg: 'Bitte bestätige die Stornierung dieser Anfrage.',
    requestAcceptedTitle: 'Anfrage akzeptiert',
    requestAcceptedMsg:
        'Als nächstes muss die andere Person sich ID-Verifizieren und die Miete über fainin bezahlen. In der Zwischenzeit könnt ihr miteinander schreiben und eine Übergabe verabreden oder offene Fragen klären.',
    requestDeclinedTitle: 'Anfrage abgelehnt',
    requestDeclinedMsg:
        'Du hast die Anfrage abgelehnt. Sollte es sich um ein Irrtum handeln, kannst du den Interessenten bitten, seine Anfrage erneut zu stellen.',
    understand: 'Verstanden',
    startingRental: 'Vermietung Starten',
    uploadLeast:
        'Herzlichen Glückwunsch! Du hast es fast geschafft. Lade mindestens 1 Foto vom Artikel hoch und bestätige damit die Vermietung.',
    warning: 'Warnung',
    rentalDateHasNotReached:
        'Die Startzeit deiner Miete wurde noch nicht erreicht. Wir können keine Schäden außerhalb des Mietzeitraums versichert. Möchtest du die Miete trotzdem beginnen?',
    endingRental: 'Vermietung Beenden',
    uploadLeastEnd: 'Lade mindestens 1 Foto vom Artikel hoch und bestätige damit die Rückgabe.',
    rentalEndDateHasNotReached:
        'Die Endzeit der Ausleihe wurde noch nicht erreicht, Möchtest du die Ausleihe trotzdem beenden?',
    refundTitle: 'Erstattung',
    refundMsg:
        'Wenn du diese Bestellung stornierst, wird eine Rückerstattungsanforderung gesendet. Möchtest du diese Bestellung wirklich stornieren und erstatten lassen?',
    changesDiscardConfirm: 'Bist du sicher? \nDeine Änderungen werden verworfen.',
    pwa: {
        title: 'Update!',
        msg: 'fainin wurde aktualisiert.',
        btnTxt: 'Neu laden',
    },
    deletingProductTitle: 'Produkt löschen',
    deletingProductMsg: "Bist du sicher, dass du dein Produckt '{{productName}}' löschen möchtest?",
    deletingAccountTitle: 'Account löschen',
    deletingAccountMsg: 'Bist du sicher, dass du deinen Account löschen lassen möchtest?',
    modalUpdateTierTitle: 'Möchtest du die Preisstaffelung aktualisieren?',
    modalUpdateTierMsg:
        'Deine derzeit konfigurierten Preisstaffelung werden durch eine neue Definition basierend auf deiner Eingabe überschrieben.',
    deletingAccountLabel: "Zum Bestätigen tippe 'LÖSCHEN' in das Eingabefeld",
    deletingAccountInput: 'LÖSCHEN',
    productUpload: {
        cacheRestore: {
            title: 'Entwurf gefunden',
            msg: 'Möchtest du deinen Produktentwurf weiter bearbeiten oder einen neues Produkt hochladen?',
            confirmLabel: 'Fortsetzen',
            cancelLabel: 'Neu',
        },
    },
    detectedContentLanguage: {
        title: 'Andere Sprache verfügbar',
        msg: 'Dieser Inhalt ist auch in deiner Sprache "{{language}}" verfügbar. Möchtest du zu dem Inhalt in deiner Sprache wechseln?',
    },
    confirmCommunityLeave: {
        title: 'Community Verlassen',
        msg: 'Möchtest du diese wirklich Community verlassen?',
    },
};
