export default {
    landingHero: {
        title: 'Rent the things you need',
        subTitle: 'Right in your neighborhood',
        yourProduct: 'Your desired product',
        upload: 'Item upload',
    },
    requestAdditional: {
        title: 'Increase you chances!',
        body: 'You know what you want to rent and need it at a certain time? Then it may make sense to send more than one request to improve your chances.',
        button: 'Go to Search',
    },
    categoryPages: {
        all: 'All',
        heroAlt: 'Hero Image',
    },
    favWhenLogin: 'Favorites can only be saved permanently when you log in',
    tapForProductsNearby: 'Tap here to see products nearby',
    safestcommunity: 'The safest sharing community',
    return: 'Return',
    filter: 'Filter',
    category: 'Category',
    community: {
        title: 'Community',
        tags: {
            university: 'University',
            tech: 'Technology',
            leisure: 'Leisure',
            business: 'Business',
        },
        requirement: {
            'single-domain': 'An email from the domain name: {{domain}}',
            'any-domain': `An email from one of the domains: {{domain}}`,
            identified: 'A verified identity',
            age: 'A minimum age of: {{age}}',
        },
    },
    reset: 'Reset',
    showProducts: 'Show Products',
    copyRight: 'COPYRIGHT',
    allRightsReserved: 'ALL RIGHTS RESERVED',
    sorting: {
        sort: 'Sort',
        refresh: 'Refresh',
        priceAsc: 'Price low to high',
        priceDesc: 'Price high to low',
        newest: 'Newest first',
        location: 'Distance',
    },
    assetGallery: {
        viewAll: 'View All',
        noAssetFound: 'No Asset Found',
    },
    collectionBreadCrumbs: {
        home: 'Home',
    },
    menuMobile: {
        navigation: 'Navigation',
        categories: 'Categories',
        faininStorefront: 'fainin Storefront',
        close: 'Close',
    },
    myProductCard: {
        day: 'day',
        perDay: 'per day',
        from: 'from',
        communityWithCount_one: '{{count}} Community',
        communityWithCount_other: '{{count}} Communities',
    },
    searchSheet: {
        loadMore: 'Load More',
        noResults: 'No results',
        no: 'No',
        results: 'Results',
    },
    addressModal: {
        okay: 'Okay',
    },
    cartContents: {
        orderAdjustments: 'Order adjustments',
        shipping: 'Shipping',
        total: 'Total',
    },
    soonLabel: {
        comingSoon: 'Coming Soon',
    },
    userComponent: {
        awarded: 'Awarded',
        onLoan: 'On Loan',
    },
    locationPermission: {
        locationDisclaimer:
            'Your exact address will not be shown to other users. They can only see the street name when searching.',
        location: 'Your city',
        locationProductUpload: 'Street & house no.',
        allowLocation: 'Allow location permission',
        useExactLocation: 'Use Exact Location',
        required: 'To pull your location once, we’ll need your permission.',
        allowLocationPointOne: 'Click <b>Allow</b> - and wait for the following <b>pop-up.</b>',
        allowLocationPointTwo: 'Allow fainin to use your current location <b>& continue where you left off.</b>',
        givePermission: 'Allow',
        missingLocation: 'Missing location permission',
        missingLocationPointOne: 'Open your <b>browser settings</b>',
        missingLocationPointTwo: '<b>Allow fainin</b> to use your current location in your settings & come back here.',
        missingLocationPointThree:
            'Click <b>Try again</b> - the screen is going to <b>refresh itself</b> & you’ll be able to enjoy all items next to you.',
        notNow: 'Not Now',
        notGiven: 'No location permission given!',
        checkInputTryAgain: 'Please check your input and try again!',
        tryAgain: 'Try Again',
        zipCity: 'Zip-code, City',
    },
    germany: 'Germany',
    currentLocation: 'Current Location',
    searchbar: 'Search for items to rent',
    NoLocation: 'No Location',
};
