<div class="hero small" [style.background-image]="'url(./assets/background/background-desktop.png)'">
    <div class="container text-center">
        <h1 class="mt-5 text-light">Datenschutzerklärung</h1>
        <span class="spacer-accent mx-auto"></span>
        <h3 class="mt-3 text-light">{{ 'content.home.sharingIsOwning' | translate }}</h3>
    </div>
</div>

<div class="container my-5 d-flex flex-column align-items-center">
    <div class="privacy-section" *ngFor="let section of sections; let i = index">
        <h2 class="text-h3" *ngIf="section.title">{{ i + 1 }}. {{ section.title }}</h2>
        <div *ngIf="section.content" [innerHTML]="section.content"></div>
        <div *ngIf="section.children">
            <div *ngFor="let child of section.children; let j = index">
                <h3 class="text-h4" *ngIf="child.title">{{ i + 1 }}.{{ j + 1 }} {{ child.title }}</h3>
                <div [innerHTML]="child.content"></div>
            </div>
        </div>
    </div>

    <div class="privacy-section">
        <small>Zuletzt bearbeitet: 29.01.2025</small>
    </div>
</div>
