<div class="community">
    <a class="community-image" [routerLink]="['/community', community.slug]">
        <ng-container *ngIf="community.featuredAsset; else fallbackImage">
            <img
                class="thumbnail"
                [ngSrc]="community.featuredAsset | assetPreview : 'small'"
                [width]="150"
                [height]="150"
                [faininFallbackImage]="'/assets/logo/icon-192x192.png'"
                [alt]="community.name" />
        </ng-container>
        <ng-template #fallbackImage>
            <img
                class="thumbnail fallback"
                [src]="'/assets/logo/icon-192x192.png'"
                width="192"
                height="192"
                [alt]="community.name" />
        </ng-template>
    </a>

    <div class="community-info" [class.no-actions]="!allowActions">
        <div class="name">
            {{ community.name }}
        </div>
        <div class="badges">
            <div class="members">
                {{ community.totalMembers }}
                <fa-icon icon="users" size="xs"></fa-icon>
            </div>
            <div class="membership" *ngIf="community.isMember">
                <fa-icon icon="user-check" size="xs"></fa-icon>
            </div>
            <div class="tags">
                <ng-container *ngFor="let tag of community.tags">
                    <span class="tag">
                        {{ 'core.community.tags.' + tag | translate }}
                    </span>
                </ng-container>
            </div>
        </div>

        <div class="description paragraph-small">{{ community.description }}</div>
    </div>

    <div class="community-actions" *ngIf="allowActions">
        <ng-container *ngIf="(actionState | async) === 'CAN_JOIN'; else showView">
            <ng-container *ngIf="signedIn$ | async; else notSignedIn">
                <fainin-button class="join" buttonClass="primary" (debounceClick)="doJoinCommunity(community)">
                    {{ 'content.community.join' | translate }}
                </fainin-button>
            </ng-container>
            <ng-template #notSignedIn>
                <fainin-button class="signin-then-join" buttonClass="primary" (debounceClick)="doSignIn()">
                    {{ 'content.community.join' | translate }}
                </fainin-button>
            </ng-template>
        </ng-container>
        <ng-template #showView>
            <fainin-button class="show" buttonClass="primary outline" [routerLink]="['/community', community.slug]">
                {{ 'content.community.show' | translate }}
            </fainin-button>
        </ng-template>
    </div>
</div>
