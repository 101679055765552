export default {
    whatsPhoneNumber: 'Bitte gebe deine Telefonnummer ein:',
    howLogin: 'Wie willst du dich einloggen?',
    notRegisteredYet: 'Noch kein Mitglied?',
    appleAuthHasFailed:
        'Die Anmeldung mit Apple hat nicht geklappt Versuche es erneut oder melde dich bei unserem Support.',
    addMoreProducts: 'Weitere Produkte inserieren',
    gotoArticle: 'Zum Artikel',
    weLetYouKnow: 'Wir sagen Dir bescheid, wenn Dir jemand eine Miet-Anfrage sendet.',
    productUploaded: 'Dein Artikel wurde hochgeladen.',
    congrats: 'Herzlichen Glückwunch!',
    success: 'Erfolgreich!',
    email: 'E-Mail Adresse',
    password: 'Passwort',
    passwordHint: 'Mindestens 8 Zeichen lang',
    passwordHint2: 'Dein Passwort sollte Zahlen, Großbuchstaben und ein Sonderzeichen enthalten',
    confirmPassword: 'Passwort bestätigen',
    rememberMe: 'Angemeldet bleiben',
    reset: 'Zurücksetzen',
    setPassword: 'Passwort setzten',
    login: 'Anmelden',
    loginWithEmail: 'Logge dich mit deiner E-Mail ein',
    alreadyHaveAccount: 'Bereits ein Konto?',
    becomeRenter: 'Verleiher werden',
    register: 'Registrieren',
    registerNow: 'Jetzt Registrieren',
    mr: 'Herr',
    mrs: 'Frau',
    notSpecified: 'Divers',
    gender: 'Geschlecht',
    birthday: 'Geburtstag',
    createAccount: 'Konto anlegen',
    noAccount: 'Noch kein Konto?',
    hasAccount: 'Sie haben schon ein Konto',
    agreedTo: 'Ich akzeptiere die',
    favoritesCannotBeSaved: 'Favoriten können erst dauerhaft gespeichert werden, wenn du dich anmeldest',
    version: 'Version',
    okay: 'Okay',
    filters: 'Filter',
    loadMore: 'Mehr laden',
    noResults: 'Keine Ergebnisse',
    of: 'von',
    imprint: 'Impressum',
    representedBy: 'Vertreten durch',
    managingDirector: 'Geschäftsführer',
    registerEntry: 'Registereintrag',
    registeredIn: 'Eingetragen im Handelsregister',
    registrationCourt: 'Registergericht: Amtsgericht Hamburg',
    registrationNumber: 'Registernummer',
    taxId: 'Umsatzsteuer-ID',
    salesTaxId: 'Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz',
    vatId: 'USt-ID',
    businessType: 'Unternehmenstyp',
    comingSoon: 'Bald verfügbar',
    privateOrCompany: 'Bist Du privat hier oder Geschäftlich?',
    whichType:
        'Wenn Du das Konto privat zum Mieten und Verleihen von Gegenständen nutzt, wähle bitte die Option "Privates Konto". Wähle das Geschäftskonto nur, wenn Du planst Gegenstände geschäftlich zu vermieten.',
    type: {
        PERSONAL: 'Persönliches Konto',
        BUSINESS: 'Geschäftskonto',
    },
    forgotPassword: {
        title: 'Passwort vergessen',
        instructions: 'Gib erst Deine fainin-Mail ein. Danach bekommst Du Hilfe per Mail',
        success: 'Eine E-Mail wurde an dich gesendet. Wenn das Konto existiert, überprüfe bitte deinen Posteingang.',
    },
    resetPassword: {
        title: 'Passwort auswählen',
        instructions: 'Wähle Dein neues fainin-Passwort',
    },
    verify: {
        title: 'Herzlich willkommen!',
        subTitle: 'Endlich kannst Du loslegen und bist Teil der fainin-Family',
        instructions: 'Es wurde eine E-Mail an Dich gesendet. Jetzt nur noch verifizieren.',
        refreshToken: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um ein neues Verifizierungs-Token anzufordern',
    },
    business: {
        title: 'Geschäft',
        type: 'Rechtsform',
        name: 'Geschäftsname/Anzeigename',
        registrationNumber: 'Registernummer/Aktiensymbol',
        vat: 'Umsatzsteuer-ID/Steuernummer',
        imprint: 'Impressum',
    },
    error: {
        googleAuthFlowRestarted: 'Anmeldung wurde vom Nutzer neu gestartet.',
        googleFailedToIssueCred: 'Fehler bei der Weitergabe der Anmeldeinformation für Google, versuche es erneut.',
        googleAuthCancelledByUser: 'Anmeldung mit Google wurde vom Nutzer unterbrochen.',
        googleOneTapOptedOut:
            'Bitte prüfe ob du Google one Tap Sign In abgestellt hast. Falls nicht bitte lösche den Cache der Webseite und versuche es erneut.',
        googleAuthSuppressedByUser: 'Anmeldung mit Google wurde vom Nutzer unterdrückt.',
        googleAuthCustomerSupport:
            'Es gab einen Fehler bei der Anmeldung mit Google, sollte das Problem andauern, bitte kontaktieren sie den Kundenservice.',
        googleAuthUnsupported:
            'Dein Browser unterstützt die Anmeldung mit Google nicht. Registriere dich mit einer E-mail oder nutze einen anderen Browser.',
        initGoogleAuthError:
            'Fehler beim initiieren der Google Authentifizierung. Bitte lade die Seite neu und versuche es erneut.',
        errorOccurred: 'Ein Fehler ist aufgetreten',
        phoneNumberError: 'Ups! Da stimmt etwas nicht mit Deiner Handynummer - hast Du einen Ländercode hinzugefügt?',
        errorDeletingLocation: 'Fehler beim Löschen des Ortes',
        removeFailed: 'Entfernen fehlgeschlagen',
        noAddressProvided: 'Keine Adresse angegeben',
        startBeforeEnd: 'Startdatum muss vor dem Enddatum liegen!!',
        failedToFindLocation: 'Ihr aktueller Standort konnte nicht gefunden werden',
        failedToCreateUploadLocation: 'Upload-Speicherort konnte nicht erstellt werden',
        failedToLocateAddress: 'Die angegebene Adresse konnte nicht gefunden werden',
        failedToGeolocate: 'Geolokalisierungsdaten konnten nicht abgerufen werden',
        invalidZip: 'Ungültige Postleitzahl für Stadt/Land',
        invalidCityName: 'Ungültiger Stadtname',
        invalidCountryName: 'Ungültiger Ländername',
        attention: 'Achtung!',
        cantVerifyZipTitle: 'Diese Postleitzahl kennen wir nicht',
        cantVerifyZip: 'Bist Du sicher, dass Du mit der aktuellen Eingabe fortfahren möchtest?',
        zipCityDontMatchTitle: 'Achtung!',
        zipCityDontMatchBody: 'Plz und Stadt passen nicht zusammen',
        startEndDates: 'Bitte wähle ein Start- und Enddatum (Beides kann der gleiche Tag sein)',
        startEndTimes: 'Bitte wähle eine Start- und Endzeit',
        invalidCredentials: 'Falsche Angaben. Versuch es gerne nochmal',
        recaptcha: 'Der ReCAPTCHA ist fehlgeschlagen',
        email: 'Bitte gib Deine Lieblings-Mail ein',
        password: 'Bitte gib ein passendes Passwort ein',
        confirmPassword: 'Achtung! Die zwei Passwörter sind unterschiedlich',
        name: 'Bitte nenne uns Deinen Namen',
        firstName: 'Bitte nenne uns Deinen Vornamen',
        lastName: 'Bitte nenne uns Deinen Nachnamen',
        gender: 'Bitte auswählen',
        businessName: 'Bitte gib Deinen Unternehmensnamen ein',
        businessType: 'Bitte gib Deinen Unternehmenstyp',
        vat: 'Bitte gib Deine USt.-Id ein',
        noToken: 'Es wurde leider kein Token gefunden',
        invalidToken: 'Der verwendete Token ist ungültig!',
        verificationTokenInvalid: 'Der Verifizierungs-Token ist ungültig.',
        verificationTokenExpired: 'Der Verifizierungs-Token ist abgelaufen.',
        title: 'Titel erforderlich (mindestens 3 Zeichen)',
        image: 'Lade ein Bild hoch',
        category: 'Bitte füge eine Kategorie hinzu',
        subCategory: 'Bitte füge eine Subkategorie hinzu',
        description: 'Schreib eine Beschreibung (min. 5 Zeichen)',
        perDay: 'Minimum 1 €',
        perHour: 'Mindestens 1 € und höchsten zwei Stellen nach dem Komma',
        basePrice: 'Mindestens 1 € und höchsten zwei Stellen nach dem Komma',
        perWeek: 'Der Wochenpreis darf nicht 0 sein',
        perMonth: 'Der monatliche Preis darf nicht 0 sein',
        perWeek2: 'Der Wochenpreis muss höher als der Tagespreis sein',
        perMonth2: 'Der Monatspreis muss höher als der Wochen- und Tagespreis sein',
        purchasePrice: 'Minimum 1 €',
        location: 'Keine Standorte gefunden. Bitte erstellen Sie einen neuen Upload-Speicherort.',
        locationName: 'Bitte Standortnamen eingeben',
        districtName: 'Bitte Bezirksnamen eingeben',
        zip: 'Postleitzahl erforderlich',
        cityName: 'Bitte geben Sie den Namen der Stadt ein',
        countryName: 'Bitte geben Sie den Ländernamen ein',
        agree: 'Muss akzeptiert werden!',
        duplicateEmail: 'Du hast dich schon einmal mit dieser E-Mail-Adresse registriert, versuche dich anzumelden!',
        nativeAuthenticationMethodsNotFound:
            'Du bist mit Social Sign-Up-Methode registriert. Versuch es damit nochmal.',
        signedUpThroughEmail: 'Es gibt schon einen Account. Versuch Dich mit Mail anzumelden',
        birthday: 'Geburtstag ist erforderlich',
        validEmail: 'Bitte gib eine E-Mail ein',
        checkInputs: 'Bitte überprüfe Deine Eingaben und versuche erneut zu speichern',
        fillAllForBank: 'Bitte fülle alle erforderlichen Felder aus, um ein Bankkonto hinzuzufügen!',
        iban: 'IBAN stimmt nicht!',
        ibanMin: 'Max. 34 Zeichen!',
        positionStack: 'Die angegebene Andresse konnte nicht gefunden werden, bitte überprüfe diese nochmal.',
        changeEmail: 'Bitte gebe Dein Passwort ein, um Deine E-Mail-Adresse zu ändern!',
        loadingError: 'Schief gelaufen, bitte überprüfe Dein Netzwerk und aktualisiere die Seite!',
        cantRemoveDefault: 'Du kannst deine standard Location nicht entfernen',
    },
    migrationOptOut: {
        title: 'Migration Abbrechen',
        instructions:
            'Wenn du das Opt-Out Verfahren startest werden alle deine Daten gelöscht. Du kannst dir natürlich dennoch einen neuen Account erstellen.',
        button: 'Opt-Out!',
        buttonLabel: 'Migration Opt-Out Button',
    },
    migrationOptOutNotification: {
        onSuccessHeading: 'Migration Abgebrochen',
        onSuccessParagraph: 'Schade dass Du uns verlässt! Deine Daten werden vollständig entfernt.',
        onError: 'Bei dem Opt-Out Prozess ist etwas schief gelaufen. Bitte melde Dich bei uns über contact@fainin.com!',
    },
    migrationOptIn: {
        title: 'Migration Abschließen',
        instructions: 'Willkommen zurück bei der fainin Family! Wähle nun Dein neues Passwort.',
        button: 'Passwort auswählen',
        buttonLabel: 'Migration Opt-In Button',
    },
    migrationOptInNotification: {
        onError: 'Bei dem Opt-In Prozess ist etwas schief gelaufen. Bitte melde Dich bei uns über contact@fainin.com!',
    },
    passwordResetNotification: {
        onSuccessHeading: 'Passwort zurückgesetzt.',
        onSuccessParagraph: 'Glückwunsch!',
    },
    checkoutConfirmation: {
        thankYouForOrder: 'Vielen Dank für deine Bestellung',
        orderCode: 'Bestellnummer',
        placed: 'erstellt',
        createAccount: 'Erstelle ein Konto',
        createAccountMore: 'Erstele ein Konto für schnellere Zahlungen, Bestellverfolgung und mehr.',
        checkEmail: 'Großartig! Du solltest nun eine Mail von uns erhalten haben',
        toCompleteRegistration: 'um die Registrierung abzuschließen',
        pageNotFound: 'Seite nicht gefunden',
        payWithMethod: 'Zahle mit Methode',
        examplePaymentForm: 'Dies ist ein Beispiel. Bitte nutze keine echten Karteninformationen',
        expiry: 'Ablaufdatum',
        month: 'Monat',
        year: 'Jahr',
        completeOrder: 'Bestellung abschließen',
        change: 'Wechseln',
        selectAddress: 'Adresse wählen',
        addNewAddress: 'Neue Adresse hinzufügen',
        customerDetails: 'Kundendaten',
        next: 'Weiter',
        shippingAddress: 'Lieferadresse',
        sendToThisAddress: 'An diese Adresse liefern',
        shippingMethod: 'Versandmethode',
        proceedToPayment: 'Weiter zur Zahlung',
        account: 'Konto',
        shipping: 'Versand',
        payment: 'Zahlung',
        complete: 'Vollständig',
        haveAccount: 'Du besitzt bereits ein Konto',
        or: 'oder',
        continueAsGuest: 'als Gast forfahren',
    },
    google: 'Weiter mit Google',
    apple: 'Weiter mit Apple',
    continueWithMail: 'Weiter mit Mail',
    termsAgreement: 'Indem du fainin verwendest, stimmst du unseren Nutzungs- und Datenschutzbestimmungen zu.',
    termsAgreement1: 'Mit der Nutzung von fainin stimmst Du',
    termsAgreement2: 'unseren Allgemeinen Geschäftsbedingungen',
    termsAgreement3: 'und',
    termsAgreement4: 'unseren Datenschutzbestimmungen zu',
    or: 'oder',
    agreeToNotify: 'Bitte akzeptiere die Allgemeine Geschäftsbedingungen.',
    next: 'Weiter',
    whatsYourName: 'Wie heißt Du?',
    MandatoryField: 'Pflichtfeld',
};
