export default {
    browseOffers: 'Browse Offers',
    specifications: 'Specifications',
    faqs: 'Frequently Asked Questions',
    relatedProducts: 'Related Products',
    nextOffer: 'Next Offer in your area!',
    includingInsuranceFrom: 'Including insurance from',
    optionalDelivery: 'Shipping option',
    pricePrefix: 'from',
    priceSuffix: 'per day',
    offers: 'Rentals Offers',
    rentProductsCloseToYou: 'Rent products close to you',
};
