import { CommunityDetail } from '@api/generated-types';

/**
 * Check if user can join community
 * @param community
 */
export function canJoinCommunity(
    community?: Pick<CommunityDetail.Fragment, 'isMember' | 'visibility' | 'requirementFilters'> | null,
): boolean {
    return (
        community != undefined &&
        !community?.isMember &&
        (community?.visibility === 'PUBLIC' ||
            (community?.visibility === 'CLOSED' && community?.requirementFilters?.length > 0))
    );
}
