export default {
    search: {
        title: 'Kein Standort angegeben',
        message: 'Bitte gib einen Standort an, um Produkte nach ihrer Distanz zu sortieren',
    },
    shipment: {
        title: 'Versand nicht angeboten',
        msg: 'Der Vermieter bietet für dieses Produkt keinen Versand an.',
        shippingMethod: 'Versandmethode*',
        pickup: 'Abholung',
        shipping: 'Versand',
    },
    error: {
        title: `Fehler!`,
    },
    warning: {
        title: `Warnung`,
    },
    success: {
        title: 'Erfolg!',
    },
    languageNotAvailable: {
        article:
            'Dieser Artikel ist in deiner Sprache nicht verfügbar, stattdessen wird der original Artikel angezeigt.',
    },
    updateCustomerPassword: {
        title: 'Erfolg!',
        message: 'Passwort ist geändert!',
    },
    updateCustomerPasswordError: {
        title: 'Passwortänderung fehlgeschlagen!',
        message: 'Bitte vergewissern Sie sich, dass Ihr altes Passwort korrekt eingegeben wurde.',
    },
    deleteProduct: {
        title: `Produkt Löschen`,
        message: `Produkt erfolgreich gelöscht.`,
    },
    activeOrderError: {
        title: `Vermietung im Gange!`,
        message: `Es besteht eine laufende Anmietung für diesen Artikel.`,
    },
    requestSent: {
        title: `Anfrage gesendet`,
        message: `Ihre Anfrage wurde erfolgreich gesendet.\n\nBitte warte auf die Antwort des Eigentümers.`,
    },
    registrationSuccess: {
        title: 'Registrierung Abgeschlossen',
        message: 'Bitte bestätige deine Registrierung durch den Link in der Email.',
    },
    registrationError: {
        title: 'Registrierung Fehlgeschlagen',
        message: 'Dein Konto konnte nicht erstellt werden.',
    },
    registrationCancel: {
        title: 'Registrierung Abbrechen',
        message: 'Möchtest du die Registrierung abbrechen?',
    },
    languageChange: {
        title: 'Sprache Geändert',
        message: 'Die Spracheinstellungen wurden erfolgreich angepasst!',
    },
    languageChangeError: {
        title: 'Sprache nicht geändert',
    },
    bugReportSuccess: {
        title: 'Gemeldet',
        message: 'Dein Bug Report wurde erfolgreich versendet',
    },
    bugReportError: {
        title: 'Bug Report konnte nicht versendet werden.',
    },
    describeExperience: {
        label: 'Beschreibung (Optional)',
        title: 'Wie waren Ihre Erfahrungen mit {{recipient}}?',
        placeholder: 'Beschreiben Sie hier kurz Ihre Erfahrungen...',
        rules: 'Beschreibung ist zu kurz',
    },
    feedback: {
        title: 'Feedback',
        placeholder: 'Beschreibe hier kurz deine Erfahrung...',
        rules: 'Beschreibung ist zu kurz',
        text: 'Wie war deine Erfahrung?',
    },
    receiveItem: {
        title: 'Artikel Erhalten',
        firstMessage: 'Bitte bestätige, dass der Artikel unbeschädigt ist und der Beschreibung entspricht.',
        secondMessage: 'Nehme mindestens zwei Fotos auf, um deine Sicherheit zu gewährleisten.',
    },
    returnItem: {
        title: 'Artikel Zurückerhalten',
        message: 'Bitte bestätige, dass der Artikel unbeschädigt ist und der Beschreibung entspricht.',
    },
    awaitEndRental: {
        title: 'Anfrage senden?',
        message: 'Möchtest du wirklich eine Anfrage senden, um die Miete zu beenden?',
    },
    endRental: {
        title: 'Warnung!',
        message: 'Möchtest du die Buchung beenden?',
    },
    awaitRefund: {
        title: 'Warnung!',
        message: 'Möchten Sie eine Rückerstattungsanfrage senden?',
    },
    cancelRental: {
        title: 'Warning',
        message: 'Möchtest du die Buchung stornieren?',
    },
    rentalEnded: {
        title: 'Buchung beendet',
        message: 'Die Buchung deines Artikel wurde erfolgreich beendet!',
    },
    refundAwaiting: {
        title: 'Anfrage gesendet',
        message: 'Ihr Antrag auf Rückerstattung wurde gesendet!',
    },
    favoriteRemoveSuccess: {
        title: 'Ein neuer Favorit',
        Message: 'Artikel erfolgreich aus Favoriten entfernt.',
        removed: 'Artikel aus Favoriten entfernt',
    },
    favoriteRemoveError: {
        message: 'Der Artikel konnte nicht aus Favoriten entfernt werden',
    },
    awaitEndRentalError: {
        message: 'Anfrage zur Beendigung der Miete konnte nicht gesendet werden.',
    },
    rentalEndedError: {
        message: 'Die Buchung konnte nicht beendet werden.',
    },
    awaitRefundError: {
        message: 'Der Antrag auf Rückerstattung konnte nicht gesendet werden.',
    },
    rentalCancelled: {
        title: 'Buchung Storniert',
        message: 'Die Buchung deines Artikels wurde storniert!',
    },
    rentalCancelledError: {
        message: 'Die Buchung konnte nicht storniert werden.',
    },
    orderDeliveredSuccess: {
        title: 'Erfolg',
        message: 'Deine Fotos wurden erfolgreich hochgeladen!',
    },
    orderDeliveredError: {
        title: 'Fehler',
        message: 'Ein Fehler ist während des Hochladens aufgekommen.',
    },
    imageSubmitError: {
        noPictures: 'Keine Fotos aufgenommen',
        twoPictures: 'Lade mindestens zwei Fotos hoch*',
    },
    termsAndConditionsCheck: {
        title: 'Allgemeine Geschäftsbedingungen',
        message: 'Akzeptiere die Allgemeinen Geschäftsbedingungen, um deine Registrierung abschließen zu können.',
    },
    requestExists: {
        title: 'Bereits angefragt',
        message: 'Du hast den Artikel bereits in dem selben Zeitraum angefragt',
    },
    invalidDates: {
        title: 'Ungültiger Zeitraum',
        message: 'Der gewählte Zeitraum deiner Anfrage ist ungültig',
    },
    itemUnavailable: {
        title: 'Artikel nicht verfügbar',
        message: 'Der Artikel ist für den gewählten Zeitraum nicht verfügbar.',
    },
    newsletterAlreadySubscribed: {
        title: 'Information',
        message: 'Super! Du hast unseren Newsletter bereits abonniert!',
    },
    voucher: {
        message: `Der Gutschein "{{code}}" über {{amount}}€ wurde angewandt.`,
    },
    noLocationResolved: {
        message: 'Für diese Eingabe konnte kein Ort mit Koordinaten ermittelt werden.',
    },
    deleteAccount: {
        request: 'Kontolöschung beantragen',
        message: 'Deine Anfrage zur Löschung deines Account wurde erfolgreich gesendet.',
    },
    upgradeAccount: {
        label: 'Geschäftskonto erstellen',
        title: 'Upgrade dein Konto',
        message:
            'Möchtest du dein Konto auf ein Geschäftskonto upgraden? Im nächsten Schritt wirst du aufgefordert, deine Unternehmensdaten einzugeben.',
    },
    community: {
        cantJoin: 'Du kannst dieser Community aktuell nicht beitreten.',
        missingRequirement: 'Du besitzt nicht die benötigten Anforderungen für den Beitritt dieser Gruppe.',
        noAccess: 'Du besitzt nicht die benötigten Anforderungen für den Zugriff auf diese Gruppe.',
        noMember: 'Du bist kein Mitglied dieser Gruppe',
        alreadyMember: 'Du bist bereits Mitglied dieser Gruppe',
        joined: 'Du bist der {{name}} Community beigetreten',
        left: 'Du hast {{name}} verlassen',
        leaveFailed: 'Beim Verlassen der Gruppe ist ein Fehler aufgetreten',
    },
    notSignedIn: {
        title: 'Nicht angemeldet',
        message: 'Du musst angemeldet sein.',
        actionMsg: 'Du musst angemeldet sein um diese Aktion durchführen zu können.',
    },
};
