export default {
    intro: 'fainin Communities are a place, where you can share exclusively with the other members and benefit from special discounts and unique rental offers. Communities are available for universities, clubs, companies and other organizations. You want a sharing-community at your university or at your workplace? Make sure to contact our team!',
    requestCommunity: 'If you want to start a community, send us a short email to contact@fainin.com.',
    recommended: 'Recommendations for you:',
    join: 'Join',
    leave: 'Leave',
    show: 'Show',
    allProducts: 'All Products',
    requiresLogin: 'To join a community you must be logged in',
    section: {
        info: 'Infos',
        intro: 'Introduction',
        members: 'Members',
        products: 'Products',
        faqs: 'FAQs',
    },
};
