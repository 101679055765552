import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UniversalCookieConsentOptions } from '@core/cookie-consent/models/universal-cookie-consent-options.model';
import { UniversalCookieConsentViewState } from '@core/cookie-consent/models/universal-cookie-consent-view-state.model';
import { UniversalCookieConsentService } from '@core/cookie-consent/services/universal-cookie-consent.service';
import { StateService } from '@core/providers/state/state.service';
import { combineLatest, lastValueFrom, Observable, Subscription } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

@Component({
    selector: 'fainin-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit, OnDestroy {
    @Input()
    stateToShow: 'ADVANCED' | 'SIMPLE';

    readonly ViewState = UniversalCookieConsentViewState;

    /**
     * The current view state
     */
    viewState$: Observable<UniversalCookieConsentViewState>;

    /**
     * The current options
     */
    options$: Observable<UniversalCookieConsentOptions>;

    /**
     * Whether to show or hide the modal
     */
    showModal$: Observable<boolean>;

    /**
     * The currently granted consents
     */
    grantedConsents$: Observable<string[]>;

    /**
     * Form group for the customize view
     */
    customizeFormGroup: FormGroup;

    /**
     * Component subscription
     */
    subscription: Subscription;

    @Output()
    customizeClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    acceptClicked: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        protected service: UniversalCookieConsentService,
        protected state: StateService,
        protected fb: FormBuilder,
    ) {
        this.viewState$ = this.service.getViewState().pipe(shareReplay(1));
        /**
         * The code is directly used from the library files.
         *
         * There is a compile-time type mismatch error in this function without @ts-ignores. I'm assuming the original
         * maintainer of the library knew that the types were compatible and just had different signatures
         * but didn't do anything to explicitly state it.
         *
         * Since the original package hasn't been updated in years, they probably didn't prioritize it when active.
         */
        // @ts-ignore
        this.options$ = this.service.getOptions().pipe(shareReplay(1));
        this.showModal$ = this.service.getViewState().pipe(
            map(viewState => {
                return (
                    viewState === UniversalCookieConsentViewState.SIMPLE ||
                    viewState === UniversalCookieConsentViewState.ADVANCED
                );
            }),
            shareReplay(1),
        );
        // @ts-ignore
        this.grantedConsents$ = this.service.getGrantedConsents();
    }

    ngOnInit() {
        this.subscription = combineLatest([this.grantedConsents$, this.options$]).subscribe(
            ([grantedConsents, options]) => {
                this.updateCustomizeForm(grantedConsents, options);
            },
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Called when the user clicks the accept button, grants all consents and closes the modal
     */
    async onAcceptClicked(event: MouseEvent) {
        event.stopPropagation();
        const options = await lastValueFrom(this.options$.pipe(take(1)));
        const consentTypes = options?.consentTypes;
        if (consentTypes) {
            this.service.setGrantedConsents(consentTypes.map(type => type.id));
            this.service.setViewState(UniversalCookieConsentViewState.CLOSED);
        }
        this.acceptClicked.emit();
    }

    /**
     * Called when the user clicks the necessary button, grants only necessary consents and closes the modal
     * @param event
     */
    async onNecessaryClicked(event: MouseEvent) {
        event.stopPropagation();
        const options = await lastValueFrom(this.options$.pipe(take(1)));
        const consentTypes = options?.consentTypes;
        if (consentTypes) {
            this.service.setGrantedConsents(consentTypes.filter(type => type.mandatory).map(type => type.id));
            this.service.setViewState(UniversalCookieConsentViewState.CLOSED);
        }
        this.acceptClicked.emit();
    }

    /**
     * Called when the user clicks the customize button, switches the view state
     */
    onCustomizeClicked(event: MouseEvent) {
        event.stopPropagation();
        this.state.setState('cookieConsentDialogOpen', true);
        this.service.setViewState(UniversalCookieConsentViewState.ADVANCED);
        this.customizeClicked.emit();
    }

    /**Called when the user clicks the back button, switches the view state*/
    /*onBackClicked() {
        this.state.setState('cookieConsentDialogOpen', false);
        this.service.setViewState(UniversalCookieConsentViewState.SIMPLE);
    }*/

    /**
     * Called when the user clicks the save button, updates the granted consents and closed the modal
     */
    async onSaveCustomizedClicked() {
        const options = await lastValueFrom(this.options$.pipe(take(1)));
        const availableConsentTypes = options?.consentTypes;
        const currentValue = this.customizeFormGroup.value;
        const grantedConsentTypes = Object.keys(currentValue).filter(consentType => currentValue[consentType]);
        const mandatoryConsentTypes = availableConsentTypes
            ?.filter(consentType => consentType.mandatory)
            .map(consentType => consentType.id);
        this.service.setGrantedConsents([...(mandatoryConsentTypes as string[]), ...grantedConsentTypes]);
        this.service.setViewState(UniversalCookieConsentViewState.CLOSED);
        this.state.setState('cookieConsentDialogOpen', false);
    }

    /**
     * Update the form with the given options
     * @param grantedConsents
     * @param options
     */
    protected updateCustomizeForm(grantedConsents: string[], options: UniversalCookieConsentOptions) {
        const controls = options.consentTypes
            ? options.consentTypes.reduce((types, type) => {
                  return {
                      ...types,
                      [type.id]: [
                          {
                              value:
                                  Boolean(type.mandatory) ||
                                  Boolean(type.default) ||
                                  (grantedConsents || []).includes(type.id),
                              disabled: Boolean(type.mandatory),
                          },
                      ],
                  };
              }, {})
            : {};
        this.customizeFormGroup = this.fb.group(controls);
    }
}
