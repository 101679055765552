import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { GenerateFeedbackDescriptionInput, GenerateProductDescriptionInput } from '@api/generated-types';
import { OpenAiGeneratorService } from '@core/providers/data/openai/open-ai-generator.service';
import { Logger } from '@core/utils/logger';
import { TranslateService } from '@ngx-translate/core';

const logger = new Logger('FeedbackComponent');

@Component({
    selector: 'fainin-feedback-step4',
    templateUrl: './feedback-step4.component.html',
    styleUrls: ['./feedback-step4.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackStep4Component {
    @Input() orderId: string;
    @Input() averageRating: number;
    @Output() submitReview: EventEmitter<string> = new EventEmitter<string>();
    comment: string;

    // Maximum number of default feedback comments configured in localization files
    readonly DEFAULT_FEEDBACK_COMMENTS = 16;

    constructor(private translate: TranslateService, private openAiGeneratorService: OpenAiGeneratorService) {
        const randomIndex = Math.min(
            Math.floor(Math.random() * this.DEFAULT_FEEDBACK_COMMENTS),
            this.DEFAULT_FEEDBACK_COMMENTS - 1,
        );
        // this.comment = this.translate.instant(`account.defaultFeedback.${randomIndex}`);
    }

    readonly generating = signal<boolean>(false);

    public generateComment() {
        this.generating.set(true);

        this.generateFeedbackDescription({ orderId: this.orderId, rating: this.averageRating }).finally(() =>
            this.generating.set(false),
        );
    }

    private generateFeedbackDescription(input: GenerateFeedbackDescriptionInput) {
        return new Promise<boolean>(resolve => {
            this.openAiGeneratorService.generateFeedbackDescription(input).subscribe({
                next: value => {
                    this.comment = value.description;
                    resolve(true);
                },
                error: (err: any) => {
                    logger.error(err);
                    resolve(false);
                },
            });
        });
    }
}
