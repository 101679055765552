<ng-container *ngIf="options$ | async as options">
    <ng-container *ngIf="showModal$ | async">
        <ng-container *ngIf="stateToShow === 'SIMPLE' && (viewState$ | async) === ViewState.SIMPLE">
            <ng-container *ngTemplateOutlet="simple"></ng-container>
        </ng-container>
        <ng-container *ngIf="stateToShow === 'ADVANCED' && (viewState$ | async) === ViewState.ADVANCED">
            <ng-container *ngTemplateOutlet="advanced"></ng-container>
        </ng-container>
    </ng-container>

    <ng-template #simple>
        <div class="cookie_consent-simple-container">
            <div class="cookie_consent-intro">
                <div class="into-title">{{ 'cookie.title' | translate }}</div>
                <p [innerHTML]="'cookie.message' | translate"></p>
            </div>

            <div class="cookie_consent-buttons-simple">
                <button
                    class="btn cookie_consent-button cookie_consent-button--primary"
                    type="button"
                    (click)="onAcceptClicked($event)">
                    {{ 'cookie.button.accept' | translate }}
                </button>

                <button
                    class="btn cookie_consent-button cookie_consent-button--primary"
                    type="button"
                    (click)="onNecessaryClicked($event)">
                    {{ 'cookie.button.necessary' | translate }}
                </button>

                <button
                    class="btn cookie_consent-button cookie_consent-button--secondary"
                    type="button"
                    (click)="onCustomizeClicked($event)">
                    {{ 'cookie.button.customize' | translate }}
                </button>
            </div>
        </div>
    </ng-template>

    <ng-template #advanced>
        <div class="cdk-overlay-container">
            <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing">
                <div class="cookie-consent-advanced-container">
                    <div class="intro-title-advanced">{{ 'cookie.title' | translate }}</div>
                    <p class="cookie_consent-intro">{{ options.customizeIntroText | translate }}</p>
                    <form class="cookie_consent-consent-types" nz-form [formGroup]="customizeFormGroup">
                        <div class="cookie_consent-consent-type" *ngFor="let consentType of options.consentTypes">
                            <nz-switch
                                class="cookie_consent-consent-type__control"
                                [formControlName]="consentType.id"></nz-switch>

                            <div class="cookie_consent-consent-type__content">
                                <h2 class="cookie_consent-consent-type__title">{{ consentType.title | translate }}</h2>
                                <p class="cookie_consent-consent-type__description">
                                    {{ consentType.description | translate }}
                                </p>
                            </div>
                        </div>
                    </form>

                    <div class="cookie_consent-buttons-simple cookie_consent-buttons-simple--advanced">
                        <button
                            class="btn cookie_consent-button cookie_consent-button--secondary"
                            type="button"
                            (click)="onSaveCustomizedClicked()">
                            {{ 'cookie.button.save' | translate }}
                        </button>
                        <button
                            class="btn cookie_consent-button cookie_consent-button--primary"
                            type="button"
                            (click)="onAcceptClicked($event)">
                            {{ 'cookie.button.accept' | translate }}
                        </button>
                    </div>
                    <div class="pb-custom pb-md-1">
                        <!-- A spacer for safari browsers to avoid a search input overlay -->
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
