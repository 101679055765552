export default {
    message: `We use cookies and similar technologies to personalize content and improve your experience on our website. By clicking “Accept all” or making adjustments in the cookie settings, you consent to their use. You can find more information on this in our <a class="privacy-link" href="/privacy">privacy policy</a>. To make changes or withdraw your consent, you can update your cookie settings at any time.`,
    title: 'We care about protecting your data',
    intro:
        'When you are visiting a website on the internet, the website can store and/or use information in your ' +
        'browser. Mostly this information is stored with cookies. This information is related to the browsing user, ' +
        'his settings or the device he is using and it is usually used to provide the user’s expected performance. ' +
        'Since this data is (mostly) anonymized, it will not identify the individual user’s personality, but help to ' +
        'provide a personalized user experience. As a matter of course, we respect your data protection rights, you ' +
        'can choose about the disclosure of your data by selecting which cookies not to allow. Though, the blocked ' +
        'cookies can affect your user experience and our provided services.',
    heading: 'Protecting Your Data',
    policy: 'Privacy Policy',
    button: {
        save: 'Save',
        back: 'Back',
        customize: 'Manage Settings',
        accept: 'Accept all',
        necessary: 'Only Necessary Cookies',
    },
    consent: {
        base: {
            title: 'Necessary cookies',
            description:
                'These cookies are necessary to the functioning and displaying of the website and cannot be ' +
                'disabled. For example the configuration of your data privacy settings, the log-in, the ' +
                'safety measure ReCaptcha or completion of forms.',
        },
        analytics: {
            title: 'Anonymous Analytics Data',
            description:
                'This setting is necessary for the anonymized evaluation of visits and traffic sources. By giving your consent to the collection of general analytics data, you enable us to understand how our website is used. This data helps us analyze and improve the performance of our site, so we can offer you a better user experience. Your data is collected and processed anonymously to protect your privacy. By doing this, you actively contribute to optimizing our content and services for you and others.',
        },
        ad_user_data: {
            title: 'Understanding Your Interests',
            description:
                'This setting allows us to collect data that helps us understand your interests and preferences better. This includes information such as the websites you visit or the products you view. This consent helps us make your experience more individual and relevant.',
        },
        ad_personalization: {
            title: 'Ads That Match You',
            description:
                'With your permission, we can use the data we have collected about you to tailor the advertisements you see personally to you. This means less time with uninteresting ads and more discoveries of products and services that really interest you. You retain control over this setting and can adjust it at any time.',
        },
        ad_storage: {
            title: 'Remembering Your Preferences',
            description:
                'Your consent allows us to store information on your device to remember your preferences and repeat visits. This ensures that your experience is as smooth as possible, with faster loading times and ads that match what you love.',
        },
    },
};
